import {Typography} from 'antd';
import {ICandidate} from "../../../services/types";

const {Text} = Typography;

interface ITruncatedEmployerInfoProps {
    item: ICandidate;
}

interface ILastEmployer {
    employer_name: string;
    employer_department: string;
    employer_years: string;
}

export const TruncatedEmployerInfo = ({item}: ITruncatedEmployerInfoProps) => {
    const lastEmployer = item.display?.last_employer as ILastEmployer | undefined;

    if (!lastEmployer?.employer_department) {
        return null;
    }

    const {employer_name, employer_department, employer_years} = lastEmployer;
    const fullText = `Letzter Arbeitgeber: ${employer_name}, ${employer_department}, ${employer_years}`;

    return (
        <div style={{
            width: '100%',
            maxWidth: '100%',
            overflow: 'hidden'
        }}>
            <Text
                ellipsis={{
                    tooltip: fullText
                }}
                style={{
                    width: '100%',
                    maxWidth: '100%',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: "#696969",
                }}
            >
                {fullText}
            </Text>
        </div>
    );
};
