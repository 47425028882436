import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import { List } from 'antd';
import { getSearches } from "../../services/api.ts";
import { SearchItem } from "./components/SearchItem.tsx";
import { ISearchResult } from "../../services/types.ts";

export const Searches = () => {
    const { data: searches = [], isLoading }: UseQueryResult<ISearchResult[], Error> = useQuery({
        queryKey: ['searches'],
        queryFn: getSearches,
    });

    const filteredSearches = searches.filter((el: ISearchResult) => el.id !== 0);

    return (
        <div className="p-4">
            <PageTitle title="Meine Suchen"/>
            <List
                loading={isLoading}
                itemLayout="horizontal"
                dataSource={filteredSearches}
                renderItem={(item) => (
                    <SearchItem item={item}/>
                )}
            />
        </div>
    );
};
