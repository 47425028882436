import { ReactNode, Suspense, useContext } from 'react';
import {Navigate} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { LayoutWrapper } from 'candidate/src/components/LayoutWrapper.tsx';
import { AuthContext } from '../context/AuthContext.tsx';
import { Loader } from '../components/Loader.tsx';
import { ErrorState } from '../components/ErrorState.tsx';
import { items } from '../components/menuItems.tsx';

type Props = {
    permissions?: string[];
    redirectTo?: string;
    children: ReactNode;
};

export const PrivateRoute = (props: Props) => {
    const { redirectTo = '/login', children, permissions } = props;
    const { isAuthenticated, user, loadingUserData, signOut } = useContext(AuthContext);
    // Check if the user's permissions or roles are valid (if required)
    const hasAllPermissions = permissions ? permissions.every(p => user?.permissions?.includes(p)) : true;

    if (loadingUserData) {
        return <Loader/>
    }
    if (!isAuthenticated) {
        return <Navigate to={redirectTo} />;
    }

    if (!hasAllPermissions) {
        return <Navigate to="/" />;
    }

    return (
        <ErrorBoundary fallback={<ErrorState/>}>
            <Suspense fallback={<Loader />}>
                <LayoutWrapper menuItems={items} user={user} signOut={signOut} isClient={true}>
                    {children}
                </LayoutWrapper>
            </Suspense>
        </ErrorBoundary>
    );
};
