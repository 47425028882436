import {ConfigProvider} from "antd";
import {BrowserRouter} from "react-router-dom";
import "antd/dist/reset.css";
import {AppRouter} from "./routes/AppRoutes";
import {colors} from "./util/colors.ts";
import {AuthProvider} from "./context/AuthContext.tsx";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorBoundary } from './bugsnag';
import "./App.css";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function AppContent() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: colors.main,
                    colorText: colors.text,
                    colorTextSecondary: colors.text,
                    borderRadius: 12,
                    colorBgContainer: colors.bg,
                },
                components: {
                    Layout: {
                        bodyBg: colors.bg,
                    },
                    Input: {
                        colorBgContainer: 'white',
                    },
                    Select: {
                        colorBgContainer: 'white',
                        colorBgElevated: 'white',
                    },
                    Menu: {
                        itemActiveBg: colors.main,
                        itemSelectedBg: colors.main,
                        itemSelectedColor: "white",
                        itemColor: colors.text,
                        itemBg: "transparent",
                        itemBorderRadius: 20,
                        itemMarginBlock: 7,
                        groupTitleFontSize: 12,
                    },
                    Button: {
                        defaultGhostBorderColor: "transparent",
                        defaultGhostColor: "transparent",
                        primaryShadow: "0px 4px 8px 0px rgba(0, 176, 187, 0.50)",
                        borderRadius: 30,
                        contentFontSize: 16,
                        paddingBlock: 8,
                        defaultColor: colors.main,
                        borderRadiusLG: 30,
                        borderRadiusSM: 30,
                        borderRadiusXS: 30,
                    },
                },
            }}
        >
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>

                        <AppRouter/>
                    </AuthProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </BrowserRouter>
        </ConfigProvider>
    );
}

function App() {
    return (
        <ErrorBoundary>
            <AppContent />
        </ErrorBoundary>
    );
}

export default App;
