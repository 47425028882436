import {useCallback, useContext, useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Input, notification, Row, Select, Space} from 'antd';
import {EditOutlined, SearchOutlined} from '@ant-design/icons';
import {IJobDemandReq, ILocation, JobTypeOption} from "../../services/types.ts";
import {
    addJobDemand,
    getHomeofficeOptions,
    getJobTypes,
    getStripeUrl,
    getTargetGroupOptions,
    getWorkloadOptions
} from "../../services/api.ts";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AuthContext} from "../../context/AuthContext.tsx";
import {AddressModal} from "../../components/AddressModal.tsx";
import {useLocation, useNavigate} from "react-router-dom";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";
import styled from "styled-components";
import {StyledInput} from "candidate/src/pages/jobPool/JobPool.tsx";
import {getToken} from "../../util/tokenCookies.ts";
import {FAQ} from "./FAQ.tsx";
import {JobSteps} from "../../components/Steps.tsx";

const {TextArea} = Input;

interface IFormValues {
    title: string;
    description: string;
    job_type: number;
    homeoffice: number;
    workload: number;
    targetGroup: number;
    contactEmail?: string | null;
}

const initialValues = {
    description: "[Tipp: Beschreiben Sie kurz und prägnant worum es geht]\n" +
        "Beispiel: Wir suchen einen neuen Kollegen für unser XYZ Team, der uns bei der Bearbeitung von telefonischen Kundenanfragen und Ablage unterstützt.\n" +
        "\n" +
        "Wo?\n" +
        "[Tipp: Nennen Sie die konkrete Anschrift und ggf. auch öffentl. Verkehrsanbindung, wenn es mehrere Standorte gibt, nennen Sie alle]\n" +
        "- Beispiel: Grolmanstraße 48, 10623 Berlin, nähe S-Bahn Savignyplatz\n" +
        "- Beispiel: An folgenden Standorten suchen wir: Charlottenburg, Wedding und Köpenick\n" +
        "\n" +
        "Was wir bieten?\n" +
        "[Tipp: Verkaufen Sie sich gut, Silvertalents sind stark nachgefragt]\n" +
        "- Beispiel: Obstkorb und Kaffee im Büro für alle\n" +
        "- Beispiel: nettes Arbeitsumfeld\n" +
        "\n" +
        "Was wir suchen?\n" +
        "[Tipp: Wenn es wichtige Anforderungen gibt listen Sie sie auf, aber denken Sie daran, dass jede Anforderung den Bewerberkreis verkleinert]\n" +
        "- Beispiel: motivierte neue Kollegen\n" +
        "- Beispiel: Kenntnisse in XYZ"
};

export const NewJobDemand = () => {
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isWhereModalOpen, setIsWhereModalOpen] = useState(false);
    const [whereSelectedValue, setWhereSelectedValue] = useState<ILocation | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const isPrivateUser = user?._client?.isPrivate;
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const isFromProfile = searchParams.get('from') === 'profile';
        if (!isFromProfile) {
            if (!user?._client?.profile_name && !user?._client?.isPrivate) {
                navigate("/new/profile");
            }
        }

    }, [location.search, navigate, user?._client]);

    const {data: jobTypes}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['jobTypes'],
        queryFn: async () => {
            const types = await getJobTypes();
            return types.map(type => ({...type, value: type.id}));
        }
    });

    const {data: homeofficeOptions}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['homeofficeOptions'],
        queryFn: async () => {
            const types = await getHomeofficeOptions();
            return types.map(type => ({...type, value: type.id}));
        }
    });

    const {data: workloadOptions}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['workloadOptions'],
        queryFn: async () => {
            const types = await getWorkloadOptions();
            return types.map(type => ({...type, value: type.id}));
        }
    });

    const {data: targetGroupOptions}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['targetGroupOptions'],
        queryFn: async () => {
            const types = await getTargetGroupOptions();
            return types.map(type => ({...type, value: type.id}));
        }
    });

    const onFinish = async (values: IFormValues) => {
        setIsLoading(true)
        if (jobTypes && homeofficeOptions && workloadOptions && targetGroupOptions && user) {
            const jobDemand: IJobDemandReq = {
                type: "job_ad",
                title: values.title,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                description: values.description,
                job_type_id: values.job_type,
                work_load_id: values.workload,
                homeoffice_id: values.homeoffice,
                job_type_label: jobTypes.find(type => type.id === values.job_type)?.label || '',
                target_group_id: values.targetGroup,
                target_group_label: targetGroupOptions.find(type => type.id === values.targetGroup)?.label || '',
                job_demand_status_id: 3,
                client_id: user.client_id,
                users_id: user.id,
                search_location_label: whereSelectedValue!.label,
                search_location_id: whereSelectedValue!.id,
                regions_id: whereSelectedValue!.type === 'region_id' ? whereSelectedValue!.value || 0 : 0,
                postal_code: whereSelectedValue!.type !== 'region_id' ? whereSelectedValue!.value?.toString() || '' : '',
                radius: whereSelectedValue!.radius || 0,
                contactEmail: values?.contactEmail || null,
            }
            try {
                const {id: newJobId} = await addJobDemand(jobDemand);
                window.scrollTo(0, 0);
                if (!isPrivateUser) {
                    navigate(user.plan_type === "paid" ? `/jobs/${newJobId}` : "/finances?from=newJobDemand")
                    return
                }
                const authToken = getToken();

                const stripeResponse = await getStripeUrl({
                    id: authToken,
                    product: "Private",
                    redirectPath: `jobs/${newJobId}`
                });
                if (stripeResponse.url) {
                    window.location.href = stripeResponse.url;
                } else {
                    notification.error({message: "Es ist ein Fehler beim Anlegen der Stellenanzeige aufgetreten."});
                }
                setIsLoading(false);
            } catch (e) {
                console.log(e)
                setIsLoading(false);
            }
        }
    };

    const onWhereLooking = () => {
        setIsWhereModalOpen(true);
    };

    const handleSaveWhere = useCallback((value: ILocation | null) => {
        if (value) {
            form.setFieldsValue({location: value.label_client});
            setWhereSelectedValue(value);
        }
    }, [form]);

    return (
        <div>
            <PageTitle title="Neue Stellenanzeige anlegen"/>
            <Row>
                {user?.plan_type !== "paid" && !isPrivateUser && <JobSteps activeStep="anzeige"/>}

                <Col span={isMobile ? 24 : 12}>
                    <Space direction="vertical" size="large" style={{width: '100%'}}>
                        <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onFinish}>
                            <Form.Item
                                name="title"
                                label="Titel der Stelle:"
                                rules={[{required: true, message: 'Bitte füllen Sie alle Felder aus.'}]}
                            >
                                <Input size={isMobile ? "large" : "middle"} placeholder="Titel der Stelle"/>
                            </Form.Item>

                            <Form.Item
                                name="location"
                                label="Standort:"
                                rules={[{required: true, message: 'Bitte füllen Sie alle Felder aus.'}]}
                            >
                                <StyledInput
                                    onClick={onWhereLooking}
                                    readOnly
                                    style={{width: '100%'}}
                                    prefix={isMobile ? <SearchOutlined/> : <EditOutlined/>}
                                    placeholder="Postleitzahl (empfohlen) oder Region wählen"
                                />
                            </Form.Item>

                            <Alert style={{marginBottom: 16}}
                                   message={(!whereSelectedValue || whereSelectedValue?.label_client.includes("Region:")) ?
                                       "Wählen Sie soweit möglich immer eine Postleitzahl und legen Sie für jeden Standort eine eigene Stellenanzeige an" :
                                       "Legen Sie für jeden Standort eine eigene Stellenanzeige an um alle Kandidaten zu erreichen"

                                   }
                                   showIcon type="info"/>
                            {isPrivateUser ? (<>
                                    <Form.Item name="workload" label="Eigenschaften:">
                                        <Select size={isMobile ? "large" : "middle"} placeholder="Arbeitspensum auswählen"
                                                options={workloadOptions}/>
                                    </Form.Item>
                                    <Form.Item name="contactEmail" label="Bewerbungen senden an:" initialValue={user?.email}
                                               rules={[{
                                                   required: true,
                                                   type: "email",
                                                   message: 'Bitte geben Sie eine gültige E-Mail-Adresse an'
                                               }]}
                                    >
                                        <Input size={isMobile ? "large" : "middle"}
                                               placeholder="Email-Adresse"/>
                                    </Form.Item>

                                </>)
                                : <>
                                    <Form.Item name="job_type" label="Eigenschaften:">
                                        <Select size={isMobile ? "large" : "middle"} placeholder="Stellentyp auswählen"
                                                options={jobTypes}/>
                                    </Form.Item>

                                    <Form.Item name="homeoffice">
                                        <Select size={isMobile ? "large" : "middle"}
                                                placeholder="Homeoffice Möglichkeiten auswählen"
                                                options={homeofficeOptions}/>
                                    </Form.Item>

                                    <Form.Item name="workload">
                                        <Select size={isMobile ? "large" : "middle"}
                                                placeholder="Arbeitspensum auswählen"
                                                options={workloadOptions}/>
                                    </Form.Item>
                                </>}
                            {!isPrivateUser && <Form.Item name="targetGroup">
                                <Select size={isMobile ? "large" : "middle"} placeholder="Zielgruppe auswählen"
                                        options={targetGroupOptions}/>
                            </Form.Item>}

                            <Form.Item name="description" label="Stellenbeschreibung (bitte anpassen):">
                                <TextArea rows={12}
                                          placeholder="[Tipp: Beschreiben Sie kurz und prägnant worum es geht] Beispiel: Wir suchen einen neuen Kolleg"/>
                            </Form.Item>

                            <Alert style={{marginBottom: 16}}
                                   message="Wenn Sie diese Stellenanzeige erstellen, wird sie noch nicht automatisch veröffentlicht. Sie können die Stellenanzeige Im nächsten Schritt veröffentlichen, damit Sie matchenden Kandidaten automatisch angezeigt wird, oder ohne Veröffentlichung für Direktansprachen benutzen."
                                   showIcon type="info"/>

                            <Form.Item>
                                <BtnWrapper>
                                    <Button onClick={() => navigate('/jobs')}>Zurück</Button>
                                    <Button type="primary" htmlType="submit" disabled={isLoading}>{user?.plan_type !== "paid" && !isPrivateUser ? "Weiter" : "Erstellen"}</Button>
                                </BtnWrapper>
                            </Form.Item>
                        </Form>
                    </Space>
                </Col>
                <Col span={isMobile ? 24 : 12} style={{paddingLeft: isMobile ? 0 : 32}}>
                    <FAQ isPrivateUser={!!isPrivateUser}/>
                </Col>
            </Row>
            <AddressModal
                open={isWhereModalOpen}
                onClose={() => setIsWhereModalOpen(false)}
                onSave={handleSaveWhere}
            />
        </div>
    );
};

const BtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`
