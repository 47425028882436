import {Button, Modal} from "antd";
import styled from "styled-components";
import {memo} from "react";
import {ISearchResult} from "../../../services/types.ts";
import {Link, useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteJobDemand} from "../../../services/api.ts";
import {RowItemWrapper} from "candidate/src/pages/searches/components/SearchItem.tsx";

export const SearchItem = memo(({item}: { item: ISearchResult }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const deleteMutation = useMutation({
        mutationFn: (id: string) => deleteJobDemand(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['searches'] });
        },
    });

    const handleViewCandidates = () => {

        const queryParams = new URLSearchParams({
            search_what_id: item.search_what_id?.toString() ?? '',
            search_what_label: item.search_what_label ?? '',
            search_location_label: item.search_location_label ?? '',
            search_location_id: item.search_location_id?.toString() ?? '',
        }).toString();

        navigate(`/candidates?${queryParams}`);
    };

    const handleDelete = () => {
        if (!item?.id) return;
        Modal.confirm({
            title: "Jobsuche wirklich löschen?",
            content: "Diese Aktion kann nicht rückgängig gemacht werden.",
            okText: "Ja",
            okType: "danger",
            cancelText: "Nein",
            onOk() {
                if (item.id) {
                    deleteMutation.mutate(item.id.toString());
                }
            },
        });
    };

    return (
        <RowItemWrapper>
            <MetaWrapper>
                <SearchTitle>{item.title ?? 'Search Title'}</SearchTitle>
                <SearchLocation>Was?: {item.search_what_label ?? 'N/A'}</SearchLocation>
                <SearchLocation>Wo?: {item.search_location_label ?? 'N/A'}</SearchLocation>
                <SearchLocation>Kandidatenvorschläge: {item.alert_label ?? 'N/A'}</SearchLocation>
            </MetaWrapper>
            <BtnGroup>
                <Button type="primary" onClick={handleViewCandidates}>Zu passenden Kandidaten</Button>
                <Link to={`/searches/${item.id}`}><Button style={{width: '100%'}}>Bearbeiten</Button></Link>
                <Button danger onClick={handleDelete} loading={deleteMutation.isPending}>Löschen</Button>
            </BtnGroup>
        </RowItemWrapper>
    );
});

const SearchTitle = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 16px;
`;

const SearchLocation = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: #424242;
`;

const BtnGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const MetaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
