import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Card, Col, Descriptions, Row, Spin } from 'antd';
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import { IMessage } from "../../services/types";
import { getCandidateFromMsgByIdApi } from "../../services/api";
import { GeocodedMap } from "../../components/Map";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {InfoRow} from "candidate/src/pages/messageDetail/MessageDetail.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";

export const CandidateMsgDetails: FC = () => {
    const { id = "" } = useParams<{ id: string }>();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const {data: candArr, isLoading, error}: UseQueryResult<IMessage[], Error> = useQuery({
        queryKey: ['candidate-det', id],
        queryFn: () => getCandidateFromMsgByIdApi(id),
        enabled: !!id,
    });

    const data = candArr ? candArr![0] : null;
    const candidate = data?._candidate_2_0;

    const address = useMemo(() => {
        if (candidate?.location) {
            return `${candidate!.location.postal_code} ${candidate!.display.city}, Deutschland`;
        }
    }, [candidate]);

    if (isLoading) return <Spin size="large" style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 100px)' }} />;
    if (error) return <Alert message="Error" description={error.message} type="error" />;
    if (!data) return <Alert message="No candidate data found" type="warning" />;

    const mainJobItems = [
        {key: '1', label: 'Letzte Tätigkeit', value: candidate!.main_job.job_position},
        {key: '2', label: 'Berufserfahrung', value: candidate!.main_job.job_experience},
        {key: '3', label: 'Weitere Tätigkeiten vorstellbar', value: candidate!.display.potential_jobs},
        {key: '4', label: 'Gewünschter Einsatzort', value: candidate!.main_job.specialization},
        {key: '5', label: 'Weitere Branchenerfahrung', value: candidate!.display.work_experience},
        {key: '6', label: 'Arbeitspensum', value: [candidate!.preferences.work_load, candidate!.preferences.work_type].filter(Boolean).join(', ')},
        {key: '7', label: 'Ehrenamtliche Tätigkeiten vorstellbar', value: candidate!.preferences.work_honorary},
        {key: '8', label: 'Sprachen', value: candidate!.additional.languages},
        {key: '9', label: 'Email', value: candidate!.contact.email},
        {key: '10', label: 'Telefon', value: candidate!.contact.phone}
    ].filter(item => item.value !== "");

    const lastEmployerItems = [
        {key: '1', label: 'Letzter Arbeitgeber', value: candidate!.display.last_employer.employer_name},
        {key: '2', label: 'Tätigkeit', value: candidate!.display.last_employer.employer_department},
        {key: '3', label: 'Verweildauer', value: candidate!.display.last_employer.employer_years}
    ].filter(item => item.value !== "");

    const previousEmployerItems = [
        {key: '1', label: 'Vorletzter Arbeitgeber', value: candidate!.display.previous_employer?.employer_name},
        {key: '2', label: 'Tätigkeit', value: candidate!.display.previous_employer?.employer_department},
        {key: '3', label: 'Verweildauer', value: candidate!.display.previous_employer?.employer_years}
    ].filter(item => item.value !== "");

    const educationItems = [
        {key: '1', label: 'Titel des höchsten Abschlusses', value: candidate!.education?.degree},
        {key: '2', label: 'Weitere Abschlüsse', value: candidate!.education?.more_title},
        {key: '3', label: 'Fortbildungen', value: candidate!.education?.trainings},
        {key: '4', label: 'Kenntnisse in relevanter Software bzw. Spezialgeräten', value: candidate!.education?.software}
    ].filter(item => item.value !== "");

    return (
        <div>
            <PageTitle
                description={`Kurzprofil - Kandidat ${candidate?.key.slice(0,6)}`}
                title={data.contact_data === 'visible' ? `${candidate!.contact.first_name} ${candidate!.contact.last_name}` : "Anonymous"}
            />
            <Row gutter={[16, 16]}>
                <Col span={isMobile ? 24 : 12}>
                    <Card title="Wichtigste Fakten" style={{ marginBottom: '24px', backgroundColor: '#f8f9fb' }}>
                        {mainJobItems.filter(item => !!item.value).map(item => {
                            return <InfoRow label={item.label} value={item.value}/>
                        })}
                    </Card>
                    {lastEmployerItems.length > 0 && (
                        <Card title="Berufserfahrung" style={{ marginBottom: '24px' }}>
                            {lastEmployerItems.filter(item => !!item.value).map(item => {
                                return <InfoRow label={item.label} value={item.value}/>
                            })}
                            {previousEmployerItems.length > 0 && (
                                previousEmployerItems.filter(item => !!item.value).map(item => {
                                    return <InfoRow label={item.label} value={item.value}/>
                                })
                            )}
                        </Card>
                    )}
                </Col>
                <Col span={isMobile ? 24 : 12}>
                    <Card title="Ort" style={{ marginBottom: 32 }}>
                        <Descriptions column={1} items={[{ key: '1', label: 'Wohnort', children: address }]} />
                        <GeocodedMap address={address} />
                    </Card>
                    {educationItems.length > 0 && (
                        <Card title="Berufsausbildung">
                            {educationItems.filter(item => !!item.value).map(item => {
                                return <InfoRow label={item.label} value={item.value}/>
                            })}
                        </Card>
                    )}
                </Col>
            </Row>
        </div>
    );
};

