import {useContext, useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {Button, Card, Col, Form, Input, Modal, Row, Select, Spin, Typography} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {getClientProfile, getIndustrySectors, getIndustrySubsectors, updateClientProfile} from "../../services/api.ts";
import {AuthContext} from "../../context/AuthContext.tsx";
import {IClientProfile, IndustrySector, IndustrySubsector} from "../../services/types.ts";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {ErrorState} from "../../components/ErrorState.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";

const {Option} = Select;
const {Text} = Typography;

const modalTitles: { [key: string]: string } = {
    profile_name: "Name des Arbeitgebers",
    contact_name: "Ansprechpartner des Arbeitgebers",
    employer_type_id: "Arbeitgebertyp",
    profile_company_size: "Unternehmensgröße",
    industry_sector_id: "Branche Ihres Unternehmens",
    profile_description: "Beschreibung Ihres Unternehmens oder Haushalts"
}

export const MyProfile = () => {
    const {user} = useContext(AuthContext);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingField, setEditingField] = useState<keyof IClientProfile | null>(null);
    const [selectedSectorId, setSelectedSectorId] = useState<number | null>(null);
    const queryClient = useQueryClient();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const isPrivateUser = user?._client?.isPrivate;

    const {data: profile, isLoading, isError} = useQuery<IClientProfile>({
        queryKey: ['clientProfile'],
        queryFn: () => {
            if (user?.client_id) {
                return getClientProfile(user.client_id);
            }
            throw new Error("Client ID is undefined");
        },
    });

    const {data: sectors} = useQuery<IndustrySector[]>({
        queryKey: ['industrySectors'],
        queryFn: getIndustrySectors,
    });

    const {data: subsectors} = useQuery<IndustrySubsector[]>({
        queryKey: ['industrySubsectors', selectedSectorId],
        queryFn: () => {
            if (selectedSectorId !== null) {
                return getIndustrySubsectors(selectedSectorId);
            }
            return Promise.resolve([]);
        },
        enabled: !!selectedSectorId,
    });

    const mutation = useMutation({
        mutationFn: (updatedData: IClientProfile) => {
            if (user?.client_id) {
                return updateClientProfile(user.client_id, updatedData);
            }
            throw new Error("Client ID is undefined");
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['clientProfile']});
            setIsModalVisible(false);
        },
    });

    useEffect(() => {
        if (profile) {
            form.setFieldsValue(profile);
        }
    }, [profile, form]);

    const handleEdit = (field: keyof IClientProfile) => {
        setEditingField(field);
        setIsModalVisible(true);
        if (field === 'industry_sector_id' && profile) {
            setSelectedSectorId(profile.industry_sector_id);
        }
    };

    const handleSave = (values: Partial<IClientProfile>) => {
        if (profile) {
            let updatedProfile = {...profile, ...values};

            if (editingField === 'industry_sector_id' && Array.isArray(subsectors)) {
                const selectedSubsector = subsectors?.find(s => s.id === values.profile_subsector);
                if (selectedSubsector) {
                    updatedProfile = {
                        ...updatedProfile,
                        profile_subsector: selectedSubsector.id,
                        subsector_label: selectedSubsector.name
                    };
                }
            }
            if (editingField === 'contact_name') {
                const isSalutationPresent = !!values.contact_salutation
                updatedProfile = {
                    ...updatedProfile,
                    contact_name: values.contact_name || "",
                    salutation_id: isSalutationPresent ? values.contact_salutation==="Frau" ? 2 : 1 : 0
                };
            }
            mutation.mutate(updatedProfile);
        }
    };

    const handleSectorChange = (value: number) => {
        setSelectedSectorId(value);
    };

    if (isLoading) {
        return <div style={{
            width: '100%',
            height: "calc(100vh - 100px)",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Spin size="large"/>
        </div>
    }
    if (isError) return <div>Error loading profile</div>;
    if (!profile) return <ErrorState/>;

    return (
        <div>
            <PageTitle
                title="Profil"
                description="Tipp: Beschreiben Sie Ihr Unternehmen oder Ihren Haushalt ausführlich, sodass Kandidaten eine gute Vorstellung über Ihr Arbeitsumfeld bekommen"
            />
            <Card title="Arbeitgeberprofil">
                <ProfileField label="Arbeitgebername" value={profile.profile_name}
                              onEdit={() => handleEdit('profile_name')}/>
                <ProfileField label="Ansprechpartner"
                              value={`${profile.contact_salutation ?? ""} ${profile.contact_name}`}
                              onEdit={() => handleEdit('contact_name')}/>
                <ProfileField label="Arbeitgebertyp" value={profile.employer_type_label}
                              onEdit={() => handleEdit('employer_type_id')}/>
                {!isPrivateUser && <ProfileField label="Größe" value={profile.company_size_label}
                              onEdit={() => handleEdit('profile_company_size')}/>}
                {!isPrivateUser && <ProfileField label="Branche"
                              value={`${profile.sector_label ?? ""}${profile.sector_label && profile.subsector_label ? " - " : ""}${profile.subsector_label ?? ""}`}
                              onEdit={() => handleEdit('industry_sector_id')}/>}
                <ProfileField label="Beschreibung" value={profile.profile_description}
                              onEdit={() => handleEdit('profile_description')}/>
            </Card>

            <Modal
                title={editingField ? `${modalTitles[editingField]}` : 'Edit Profile'}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form onFinish={handleSave} form={form}>
                    {editingField === 'profile_name' && (
                        <Form.Item name="profile_name" label="Name">
                            <Input size={isMobile? "large" : "middle"}/>
                        </Form.Item>
                    )}
                    {editingField === 'contact_name' && (
                        <>
                            <Form.Item
                                name='contact_salutation'
                                label="Anrede"
                            >
                                <Select style={{width: '39%'}} placeholder="Anrede auswählen">
                                    <Option value="" disabled>Anrede auswählen</Option>
                                    <Option value="Herr">Herr</Option>
                                    <Option value="Frau">Frau</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="contact_name" label="Name">
                                <Input size={isMobile? "large" : "middle"}/>
                            </Form.Item>
                        </>
                    )}
                    {editingField === 'employer_type_id' && (
                        <Form.Item name="employer_type_id" label="Arbeitgebertyp">
                            <Select>
                                <Option value={0} disabled>Arbeitgebertyp auswählen</Option>
                                <Option value={1}>Unternehmen</Option>
                                <Option value={2}>Privater Haushalt</Option>
                            </Select>
                        </Form.Item>
                    )}
                    {editingField === 'profile_company_size' && (
                        <Form.Item name="profile_company_size" label="Größe des Unternehmen">
                            <Select  placeholder="Größe auswählen">
                                <Option value={0} disabled>Größe auswählen</Option>
                                <Option value={1}>1-2 Mitarbeiter</Option>
                                <Option value={2}>3-10 Mitarbeiter</Option>
                                <Option value={3}>11-100 Mitarbeiter</Option>
                                <Option value={4}>101-500 Mitarbeiter</Option>
                                <Option value={5}>501-5000 Mitarbeiter</Option>
                                <Option value={6}>5000+ Mitarbeiter</Option>
                            </Select>
                        </Form.Item>
                    )}
                    {editingField === 'industry_sector_id' && (
                        <>
                            <Form.Item name="industry_sector_id" label="Sektor Ihres Unternehmens">
                                <Select onChange={handleSectorChange}>
                                    <Option key={0} value={0} disabled>Sektor auswählen</Option>
                                    {sectors && sectors?.map((sector) => (
                                        <Option key={sector.id} value={sector.id}>{sector.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="profile_subsector" label="Untersektor Ihres Unternehmens">
                                <Select>
                                    <Option key={0} value={0} disabled>Untersektor</Option>
                                    {subsectors && subsectors?.map((subsector) => (
                                        <Option key={subsector.id} value={subsector.id}>{subsector.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </>
                    )}
                    {editingField === 'profile_description' && (
                        <Form.Item name="profile_description" label="Beschreibung">
                            <Input.TextArea rows={4}/>
                        </Form.Item>
                    )}
                    <Form.Item style={{justifyContent: "flex-end", marginBottom: 0, display: "flex"}}>
                        <Button type="primary" htmlType="submit">
                            Speichern
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

interface ProfileFieldProps {
    label: string;
    value: string | number;
    onEdit: () => void;
}

const ProfileField: React.FC<ProfileFieldProps> = ({label, value, onEdit}) => (
    <Row justify="start" gutter={16} align="top" style={{padding: '8px 0'}}>
        <Col>
            <Text strong>{label}</Text>
            <br/>
            <Text>{value}</Text>
        </Col>
        <Col>
            <EditOutlined onClick={onEdit} style={{cursor: 'pointer'}}/>
        </Col>
    </Row>
);
