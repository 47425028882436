import {useContext, useState} from "react";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {Alert, Button, Card, Col, notification, Radio, Row, Space, Typography} from "antd";
import {CheckCircleOutlined, CheckOutlined} from "@ant-design/icons";
import {AuthContext} from "../../context/AuthContext";
import {getToken} from "../../util/tokenCookies.ts";
import {useMediaQuery} from "@uidotdev/usehooks";
import {useNavigate} from "react-router-dom";
import {getStripeUrl} from "../../services/api.ts";
import {FAQ} from "../newJobDemand/FAQ.tsx";
import {JobSteps} from "../../components/Steps.tsx";

const {Title, Text} = Typography;

interface PlanFeatures {
    name: string;
    descMonthly?: string;
    descYearly?: string;
    desc?: string;
    monthlyPrice?: number;
    semiannualPrice?: number;
    monthlyKey?: string;
    yearlyKey?: string;
    planKey?: string;
    price?: number;
    mainFeature: string;
    features: string[];
}

export const Finances = () => {
    const {user} = useContext(AuthContext);
    const [billingFrequency, setBillingFrequency] = useState("monthly");
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const isPrivateUser = user?._client?.isPrivate;
    const navigate = useNavigate();
    const isComingFromNewJobDemand = new URLSearchParams(location.search).get('from') === 'newJobDemand';

    const businessPlans = [
        {
            name: "Basic",
            descMonthly: "pro Monat,\n" +
                "monatlich abgerechnet",
            descYearly: "pro Monat,\n" +
                "halbjährlich abgerechnet",
            monthlyPrice: 79,
            semiannualPrice: 59,
            monthlyKey: "Riskless-monthly",
            yearlyKey: "Riskless-yearly",
            mainFeature: "1 Stellenanzeige",
            features: [
                "Active Sourcing: Kandidaten gezielt anschreiben",
                "E-Mail Benachrichtigung für neue Kandidaten"
            ]
        },
        {
            name: "Business",
            descMonthly: "pro Monat,\n" +
                "monatlich abgerechnet",
            descYearly: "pro Monat,\n" +
                "halbjährlich abgerechnet",
            monthlyPrice: 159,
            semiannualPrice: 119,
            monthlyKey: "Essential-monthly",
            yearlyKey: "Essential-yearly",
            mainFeature: "bis zu 5 Stellenanzeigen",
            features: [
                "alle Features des Basic Plans plus:",
                "Account Manager für Optimierung"
            ]
        },
        {
            name: "Business Plus",
            descMonthly: "pro Monat,\n" +
                "monatlich abgerechnet",
            descYearly: "pro Monat,\n" +
                "halbjährlich abgerechnet",
            monthlyPrice: 239,
            semiannualPrice: 179,
            monthlyKey: "Professional-monthly",
            yearlyKey: "Professional-yearly",
            mainFeature: "bis zu 10 Stellenanzeigen",
            features: [
                "alle Features des Business Plans plus:",
                "Erstzugriff auf neue Kandidaten",
                "Premium Stellenanzeige"
            ]
        },
    ];

    const privatePlan = {
        name: "Privatperson Tarif",
        desc: "Einmalzahlung\nfür eine Stellenanzeige",
        price: 69,
        planKey: "Private",
        mainFeature: "1 Stellenanzeige",
        features: [
            "Active Sourcing: Kandidaten gezielt anschreiben",
            "E-Mail Benachrichtigung für neue Kandidaten",
            "30 Tage Laufzeit"
        ]
    };

    const authToken = getToken();

    const getBillingUrl = (planKey?: string) => {
        if (!planKey) {
            return '#';
        }
        const baseUrl = import.meta.env.VITE_BILLING_URL || "https://xmfg-jflm-x0lm.f2.xano.io/api-4GH7XfR7";
        if (isPrivateUser) {
            return `https://xmfg-jflm-x0lm.f2.xano.io/api:jLUccEA3/checkout_session_private?id=${authToken}&product=${planKey}`;
        }
        return `${baseUrl}/checkout_session?id=${authToken}&product=${planKey}`;
    };

    const handleBookingClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!user?._client?.profile_name && !isPrivateUser) {
            e.preventDefault();
            navigate('/new/profile?from=finances');
            return;
        }
        if (isPrivateUser) {
            e.preventDefault();
            const authToken = getToken();

            const stripeResponse = await getStripeUrl({id: authToken, product: "Private", redirectPath: `jobs`});
            if (stripeResponse.url) {
                window.location.href = stripeResponse.url;
            } else {
                notification.error({message: "Es ist ein Fehler beim Anlegen der Stellenanzeige aufgetreten."});
            }
        }
    };

    const renderPricingCard = (plan: PlanFeatures, isPrivate = false) => {
        const isPlanActive = isPrivate
            ? user?.plan_label === plan.planKey
            : (billingFrequency === "monthly"
                ? user?.plan_label === plan.monthlyKey
                : user?.plan_label === plan.yearlyKey);

        const currentPlanKey = isPrivate
            ? plan.planKey
            : (billingFrequency === "monthly" ? plan.monthlyKey : plan.yearlyKey);

        const price = isPrivate
            ? plan.price
            : (billingFrequency === "monthly" ? plan.monthlyPrice : plan.semiannualPrice);

        const description = isPrivate
            ? plan.desc
            : (billingFrequency === "monthly" ? plan.descMonthly : plan.descYearly);

        return (
            <Card
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: isPrivate ? '400px' : 'auto'
                }}
                headStyle={{
                    backgroundColor: '#1a3368',
                    padding: '16px',
                    textAlign: "center"
                }}
                bodyStyle={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
                title={
                    <Space>
                        <Title level={4} style={{color: 'white', margin: 0}}>{plan.name}</Title>
                        {isPlanActive && <CheckOutlined style={{color: 'white', fontSize: '18px'}}/>}
                    </Space>
                }
            >
                <Space direction="vertical" size="small" style={{marginBottom: 'auto', width: '100%'}}>
                    <Title level={2} style={{textAlign: "center"}}>€ {price ?? "N/A"},-</Title>
                    <Text style={{textAlign: "center", display: 'block'}}>{description ?? ""}</Text>
                    <Text style={{textAlign: "center", display: 'block', marginBottom: 8}}>
                        {plan.mainFeature}
                    </Text>
                    <ul style={{paddingLeft: '20px'}}>
                        {plan.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                </Space>
                {!isPlanActive && (
                    <a href={getBillingUrl(currentPlanKey)} onClick={handleBookingClick}>
                        <Button
                            type="primary"
                            block
                            style={{backgroundColor: '#5CBFB9', borderColor: '#5CBFB9', marginTop: 'auto'}}
                        >
                            {isComingFromNewJobDemand ? "Produkt auswählen" : "Jetzt Buchen"}
                        </Button>
                    </a>
                )}
            </Card>
        );
    };

    return (
        <div>
            <PageTitle title={isComingFromNewJobDemand ? "Wählen Sie die Art der Anzeige" : "Finanzielles"}/>
            {isComingFromNewJobDemand && <JobSteps activeStep="anzeigenart"/>}

            {user?.plan_type === "paid" && !isPrivateUser && (
                <Alert
                    message={`Sie haben erfolgreich den silvertalent ${user.display_plan_label} plan gebucht.`}
                    type="success"
                    showIcon
                    icon={<CheckCircleOutlined/>}
                    style={{marginBottom: '20px'}}
                />
            )}

            {!isPrivateUser && (
                <Radio.Group
                    value={billingFrequency}
                    onChange={(e) => setBillingFrequency(e.target.value)}
                    style={{marginBottom: '20px', textAlign: "center", width: '100%'}}
                >
                    <Radio value="monthly">Monatlich abgerechnet</Radio>
                    <Radio value="semiannual">
                        Halbjährlich abgerechnet <Text type="success">(Sparen Sie bis zu 25%)</Text>
                    </Radio>
                </Radio.Group>
            )}

            {isPrivateUser ? (
                <div style={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
                    {renderPricingCard(privatePlan, true)}
                </div>
            ) : (
                <Row gutter={16}
                     style={{display: 'flex', flexWrap: isMobile ? 'wrap' : 'nowrap', justifyContent: "center"}}>
                    {businessPlans.map((plan) => (
                        <Col key={plan.name} span={isMobile ? 24 : 7}>
                            {renderPricingCard(plan)}
                        </Col>
                    ))}
                </Row>
            )}

            <Text type="secondary"
                  style={{display: 'block', maxWidth: "70%", marginTop: '20px', textAlign: "justify", fontSize: 12}}>
                Preise verstehen sich netto zzgl. der gesetzlichen MwSt. <br/>
                {!isPrivateUser && (<>Sie können silvertalent jederzeit kündigen. Nachdem Sie gekündigt haben, endet Ihre Mitgliedschaft
                    nach
                    Ablauf der aktuellen Rechnungsperiode. <br/></>)}
                {!isPrivateUser && (
                    <>
                        Bei der halbjährlichen Zahlung wird die Gesamtsumme für 6 Monate zu Beginn der Rechnungsperiode
                        abgerechnet. <br/>
                        Wenn Sie derzeit mehr Stellen veröffentlicht haben, als Ihr neuer Plan vorsieht, werden die
                        ältesten
                        Stellenanzeigen nicht mehr veröffentlicht. Sie können die Auswahl jederzeit unter "Meine
                        Stellen"
                        ändern.
                    </>
                )}
            </Text>
            <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <FAQ isPrivateUser={!!isPrivateUser}/>
            </div>
            <Card style={{marginTop: '20px', backgroundColor: 'white'}}>
                <Title level={5}>Haben Sie Fragen?</Title>
                <Text>
                    Wenden Sie sich an Ihren persönlichen Accountmanager oder lesen Sie Details zu unseren Plänen auf
                    unserer Website!
                </Text>
                <br/>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Space direction="vertical" style={{marginTop: '10px'}}>
                        <Text strong>Christian Ahrendt</Text>
                        <Text>Kontakt:</Text>
                        <Text>christian.ahrendt@silvertalent.com</Text>
                        <Text>030 / 520 144 73</Text>
                    </Space>
                    <a href="https://www.silvertalent.com/plaene" target="_blank">
                        <Button type="default">
                            Mehr Informationen
                        </Button>
                    </a>
                </div>
            </Card>
        </div>
    );
};

