import {Collapse, CollapseProps} from "antd";
import {BulbOutlined} from "@ant-design/icons";
import {colors} from "../../util/colors.ts";

interface FAQProps {
    isPrivateUser: boolean;
}

const nonPrivateClientFAQItems: CollapseProps['items'] = [
    {
        key: '1',
        label: 'Welche Kosten entstehen?',
        children: <p>Die Kosten sind davon abhängig, wie viele Stellen Sie inserieren. Die genauen Tarife sehen Sie auf
            der nächsten Seite. Darüber hinaus kommen keine weiteren Kosten auf Sie zu, egal wie viele Bewerbungen Sie
            erhalten oder Einstellungen vornehmen.</p>
    },
    {
        key: '2',
        label: 'Verlängert sich das Inserat automatisch?',
        children: <p>Wenn Sie nicht mindestens 1 Tag vor Ende der gewählten Laufzeit kündigen, verlängert sich Ihr
            Inserat automatisch um die gewählte Laufzeit. Zum Beispiel verlängert sich das monatlich abgerechnete
            Inserat, welches am 03. Mai gekauft wurde, an jedem 3. Tag des Monats um einen weiteren Monat, sofern es
            nicht gekündigt wird. Bei halbjährlicher Abrechnung verlängert sich die Laufzeit entsprechend um 6
            Monate.</p>
    },
    {
        key: '3',
        label: 'Gibt es Kündigungsfristen bzw. eine Mindestlaufzeit?',
        children: <p>Eine Mindestlaufzeit gibt es nicht und Sie können jederzeit vor Ende der gewählten Laufzeit
            kündigen. Wenn Sie zum Beispiel am 03. Mai ein Inserat kaufen, sollten Sie bis zum 02. Juni kündigen, damit
            sich die Mitgliedschaft nicht um einen weiteren Monat verlängert.</p>
    },
    {
        key: '4',
        label: 'Wie kann ich kündigen?',
        children: <p>Schreiben Sie uns einfach eine Email an info@silvertalent.com</p>
    },
    {
        key: '5',
        label: 'Wo kann ich meine Rechnungsinformationen hinterlegen?',
        children: <p>Im Bezahlvorgang können Sie Ihre Rechnungsadresse angeben, nachdem Sie die Zahlungsmethode
            ausgewählt haben. Klicken Sie dazu das Kästchen "Als Firma einkaufen".</p>
    },
    {
        key: '6',
        label: 'Wann erhalte ich eine Rechnung?',
        children: <p>Nach Zahlungseingang erhalten Sie automatisch eine Rechnung via Email. Sofern Sie per Lastschrift
            zahlen, kann dies bis zu 2 Wochen dauern. Die Rechnung wird an die im silvertalent Profil hinterlegte E-Mail
            Adresse gesendet.</p>
    },
    {
        key: '7',
        label: 'Welche Zahlungsmethoden gibt es?',
        children: <p>Sie können entweder per Kreditkarte, Paypal oder Lastschrift bezahlen.</p>
    },
    {
        key: '8',
        label: 'Wie lange wird meine Stellenanzeige geschaltet?',
        children: <p>Ihre Anzeigen bleiben aktiv, bis Sie sie deaktivieren, löschen oder bis zum Ende der Laufzeit der
            Mitgliedschaft.</p>
    },
    {
        key: '9',
        label: 'Wie werde ich über neue Bewerber informiert?',
        children: <p>Sie erhalten eine Email sobald eine neue Bewerbung eintrifft. Stellen Sie sicher, dass die korrekte
            Email Adresse als Empfänger für Bewerbungen hinterlegt ist.</p>
    },
    {
        key: '10',
        label: 'Wie kann ich mit Bewerbern Kontakt aufnehmen?',
        children: <p>Sie werden per Email informiert, sobald eine neue Bewerbung eintrifft. Über den Link in der Email
            gelangen Sie in Ihr silvertalent Profil und können das Kurzprofil des Bewerbers, dessen Nachricht und,
            sofern freigegeben, dessen private Kontaktdaten einsehen. Sie können den Bewerber dann direkt über
            silvertalent oder ggf. über die angegebene Email und Telefonnummer kontaktieren.</p>
    }
];

const privateClientFAQItems: CollapseProps['items'] = [
    {
        key: '1',
        label: 'Welche Zahlungsmethoden gibt es?',
        children: <p>Sie können entweder per Kreditkarte, Paypal oder Lastschrift bezahlen.</p>
    },
    {
        key: '2',
        label: 'Wie lange wird meine Stellenanzeige geschaltet?',
        children: <p>Die Stellenanzeige hat eine Laufzeit von 30 Tagen.</p>
    },
    {
        key: '3',
        label: 'Wie werde ich über neue Bewerber informiert?',
        children: <p>Sie erhalten eine Email sobald eine neue Bewerbung eintrifft. Stellen Sie sicher, dass die korrekte
            Email Adresse als Empfänger für Bewerbungen hinterlegt ist.</p>
    },
    {
        key: '4',
        label: 'Wie kann ich mit Bewerbern Kontakt aufnehmen?',
        children: <p>Sie werden per Email informiert, sobald eine neue Bewerbung eintrifft. Über den Link in der Email
            gelangen Sie in Ihr silvertalent Profil und können das Kurzprofil des Bewerbers, dessen Nachricht und,
            sofern freigegeben, dessen private Kontaktdaten einsehen. Sie können den Bewerber dann direkt über
            silvertalent oder ggf. über die angegebene Email und Telefonnummer kontaktieren.</p>
    }
];

export const FAQ = ({isPrivateUser}: FAQProps) => {
    const items = isPrivateUser ? privateClientFAQItems : nonPrivateClientFAQItems;

    return (
        <div>
            <h2>
                <BulbOutlined style={{color: colors.main, marginRight: 8}}/> Häufige Fragen
            </h2>
            <Collapse items={items} className="w-full"/>
        </div>
    );
};
