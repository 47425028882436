import {FC, useEffect, useState} from "react";
import {Input, Modal, Select} from "antd";
import {useMediaQuery} from "@uidotdev/usehooks";

const {Option} = Select;

interface OptionType {
    value: string;
    label: string;
}

export type ValueType = { value: string; secondValue?: string; };

interface EditModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (value: ValueType) => void;
    title: string;
    initialValue: string | ValueType;
    inputType: 'input' | 'select' | 'double-select';
    options?: OptionType[];
    secondOptions?: OptionType[];
}

export const EditModal: FC<EditModalProps> = ({
                                                  isOpen,
                                                  onClose,
                                                  onSave,
                                                  title,
                                                  initialValue,
                                                  inputType,
                                                  options,
                                                  secondOptions
                                              }) => {
    const [value, setValue] = useState<string>('');
    const [secondValue, setSecondValue] = useState<string>('Arbeitszeit auswählen');
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    useEffect(() => {
        if (typeof initialValue === 'string') {
            setValue(initialValue);
        } else if (initialValue) {
            setValue(initialValue.value);
            setSecondValue(initialValue.secondValue ?? 'Arbeitszeit auswählen');
        }
    }, [initialValue]);

    const handleSave = () => {
        let saveValue: ValueType;

        if (inputType === 'double-select') {
            saveValue = {value, secondValue};
        } else if (inputType === 'select') {
            saveValue = {value};
        } else {
            saveValue = {value};
        }

        onSave(saveValue);
        onClose();
    };

    return (
        <Modal title={titleMap[title]} open={isOpen} onOk={handleSave} onCancel={onClose} okText="Speichern"
               cancelText="Abbrechen">
            {inputType === 'input' && (
                <Input size={isMobile? "large" : "middle"} value={value} onChange={(e) => setValue(e.target.value)}/>
            )}
            {inputType === 'select' && (
                <Select value={value} onChange={setValue} style={{width: '100%'}}>
                    {options?.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            )}
            {inputType === 'double-select' && (
                <>
                    Stellentyp:
                    <Select value={value} onChange={setValue} style={{width: '100%', marginBottom: '10px'}}>
                        {options?.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                    Arbeitspensum:
                    <Select value={secondValue} onChange={setSecondValue} style={{width: '100%'}} placeholder="Arbeitszeit auswählen">
                        {secondOptions?.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </>
            )}
        </Modal>
    );
};

const titleMap: Record<string, string> = {
    "Meine Suche": "Titel der offenen Stelle",
    "Homeoffice - Möglichkeiten": "Homeoffice - Möglichkeiten",
    "Stellentyp und -pensum": "Stellentyp und Arbeitspensum",
    "Einstellungen": "Einstellungen der offenen Stelle",
}
