import {ReactNode, Suspense, useContext} from 'react'
import { Navigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import {AuthContext} from "../context/AuthContext.tsx";
import {ErrorState} from "../components/ErrorState.tsx";
import {Loader} from "../components/Loader.tsx";

type Props = {
    children: ReactNode
}

export const PublicRoute = (props: Props) => {
    const { children } = props

    const { isAuthenticated } = useContext(AuthContext)

    if (isAuthenticated) {
        return <Navigate to="/" />
    }

    return (
        <ErrorBoundary
            fallback={<ErrorState />}
        >
            <Suspense fallback={<Loader />}>{children}</Suspense>
        </ErrorBoundary>
    )
}

