import {Alert, Modal} from "antd";
import styled from "styled-components";
import {LineChartOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {IJobDemand} from "../../../services/types.ts";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteJobDemand} from "../../../services/api";
import {FC, memo} from "react";
import {BtnStyledMessageItem, ButtonGroupMessageItem} from "candidate/src/pages/messages/components/MessageItem.tsx";
import {
    BgWrapper,
    ItemDetails,
    RowItemWrapper
} from "candidate/src/pages/jobPool/components/JobPoolItem.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";

interface JobItemProps {
    item: IJobDemand;
    onEdit: () => void;
}

interface JobAlertProps {
    status: number;
}

export const JobItem: FC<JobItemProps> = memo(({item, onEdit}) => {
    const queryClient = useQueryClient();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const deleteMutation = useMutation({
        mutationFn: (id: string) => deleteJobDemand(id),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['jobs']});
            queryClient.invalidateQueries({queryKey: ['user']});
        },
    });

    const handleDelete = () => {
        if (!item?.id) return;
        Modal.confirm({
            title: "Möchten Sie diesen Job wirklich löschen?",
            content: "Diese Aktion kann nicht rückgängig gemacht werden.",
            okText: "Ja",
            okType: "danger",
            cancelText: "Nein",
            onOk() {
                if (item.id) {
                    deleteMutation.mutate(item.id.toString());
                }
            },
        });
    };

    return (
        <RowItemWrapper>
            <BgWrapper style={{marginBottom: isMobile ? 12 : 0}}>
                <ItemDetails style={{width: "100%"}}>
                    <JobMetaWrapper>
                        <JobTitle>{item.title ?? 'Job Title'}</JobTitle>
                        <JobLocation>Standort: {item.search_location_label ?? 'N/A'}</JobLocation>
                        <JobAlert status={item.job_demand_status_id}/>
                    </JobMetaWrapper>
                    <ButtonGroupMessageItem>
                        <BtnStyledMessageItem type="primary" onClick={onEdit}>Stelle bearbeiten</BtnStyledMessageItem>
                        <BtnStyledMessageItem onClick={handleDelete}
                                              loading={deleteMutation.isPending}>Löschen</BtnStyledMessageItem>
                    </ButtonGroupMessageItem>
                </ItemDetails>
            </BgWrapper>
        </RowItemWrapper>
    )
        ;
});

const JobAlert: FC<JobAlertProps> = ({status}) => (
    <Alert
        message={status === 4 ? 'Ihre Stellenanzeige ist veröffentlicht und wird passenden Kandidaten automatisch vorgestellt.' : 'Bitte veröffentlichen Sie Ihre Stellenanzeige über "Stelle bearbeiten", um Bewerbungen zu erhalten.'}
        type="info"
        showIcon
        icon={status === 4 ? <LineChartOutlined/> : <QuestionCircleOutlined/>}
    />
);

const JobTitle = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 16px;
`;

const JobLocation = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    color: #424242;
`;

const JobMetaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
