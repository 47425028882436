import {FC, useContext, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {AuthContext} from "../../context/AuthContext";
import {getJobByKey, getMessages} from "../../services/api";
import {Loader} from "../../components/Loader.tsx";

export const MagicLinkRedirect: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {signOut, signIn, isAuthenticated} = useContext(AuthContext);
    const hasAttemptedAuth = useRef(false);

    localStorage.setItem("redirectPath", location.pathname)

    useEffect(() => {
        const handleMagicLink = async () => {
            const searchParams = new URLSearchParams(location.search);
            const param2 = searchParams.get('param2');
            const id = searchParams.get('id') || "";
            const target = searchParams.get('target');

            const navigateToTarget = async () => {
                switch (target) {
                    case '9mhpkho8n7442ih74cn1wet01': {
                        const linkToPath = "/finances"
                        localStorage.setItem('redirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case '3qprvnkfc6jfgys0adz20omsb':
                    case 'd1uqdr81nss8p63cjl2wyeepf':
                    case '0zyfk7sx422ygdox22tylvwns':
                    case 'candidate_lp_communication_list':
                    case 'candidate_lp_job_detail_communications':
                    case 'client_communication_detail':
                    case 'client_communication_candidate': {
                        const linkToPath = `/messages/${id}`
                        localStorage.setItem('redirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case 'jobdemand_detail_page':
                    case '90ajomz550bpqje08ki8gix0e':
                    case 'ayfsb5i71ki5216qte71yolhn':
                    case '2ojzpauuxi6nqbk8qw2rmb34g': {
                        const IdofJobFromKey = await getJobByKey(id);
                        const linkToPath = `/jobs/${IdofJobFromKey[0].id}`
                        localStorage.setItem('redirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case 'candidate_detail_page':
                    case '1vjzic6pqnq3fgjmt8le5bkmw':
                    case '2ohgbdsg7q1g020lw623gyfg9': {
                        const communications = await getMessages({limit: 100, offset: 0})
                        const communicationsCand = communications.filter(el => el._candidate_2_0?.key === id)?.[0]

                        const linkToPath = communicationsCand?.key ? `/details/${communicationsCand?.key}`: `/candidate/${id}`

                        localStorage.setItem('redirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case '9qorkovfiichrwpol3dlx2zp2':
                    case '26xq2uk7glh17getfoshnntv5': {
                        const linkToPath = "/jobs"
                        localStorage.setItem('redirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    case 'candidate_lp_profile':
                    case '1kourgddi00entv62rtilafy5': {
                        const linkToPath = "/profile"
                        localStorage.setItem('redirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                    default: {
                        const linkToPath = "/"
                        localStorage.setItem('redirectPath', linkToPath || '/');
                        navigate(linkToPath);
                        break;
                    }
                }
            };

            if ((param2 || id) && !hasAttemptedAuth.current) {
                if (isAuthenticated) {
                    await signOut()
                    localStorage.removeItem('redirectPath');
                }
                hasAttemptedAuth.current = true;
                try {
                    await signIn({
                        email: param2 ? "token@client.silvertalent.com" : "token@silvertalent.com",
                        password: param2 || id
                    });
                    navigateToTarget().then(() => {
                        setTimeout(() => localStorage.removeItem("redirectPath"), 2000)
                    })
                } catch (error) {
                    console.error('Authentication failed:', error);
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        };

        handleMagicLink();
    }, [isAuthenticated, location, navigate, signIn, signOut]);

    return <Loader/>;
};
