import {ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
    BellOutlined,
    CheckCircleOutlined,
    CloseOutlined,
    EditOutlined,
    EnvironmentOutlined,
    SearchOutlined
} from '@ant-design/icons';
import {useCandidateCount, useCandidatePool} from "./hooks/useCandidatePool";
import styled from "styled-components";
import {colors} from "../../util/colors";
import {Button, message, Modal, Radio, Skeleton, Spin, Tooltip} from "antd";
import {VariableSizeList as VirtualList} from 'react-window';
import InfiniteLoader from "react-window-infinite-loader";
import {IJobDemand, IJobDetails, ILocation, IPosition} from "../../services/types";
import {addJobDemand, updateSearchById} from "../../services/api";
import {AuthContext} from "../../context/AuthContext.tsx";
import {ProfessionModal} from "../../components/ProfessionModal.tsx";
import {AddressModal} from "../../components/AddressModal.tsx";
import {useMutation} from "@tanstack/react-query";
import {alertOptions} from "../../util/utils.ts";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {TableRow} from "./TableRow.tsx";
import {CandidateItem} from "./components/CandidateItem.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";
import {
    InputLabels,
    SaveFilter,
    SearchContainer,
    StyledCloseBtn,
    StyledInput,
    TableWrapper
} from "candidate/src/pages/jobPool/JobPool.tsx";

const LIMIT = 20;

export const CandidatePool: FC = () => {
    const {user, isNewUserBannerVisible, setBannerVisible} = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const [containerHeight, setContainerHeight] = useState(window.innerHeight - 176);
    const isPrivateUser = user?._client?.isPrivate;

    useEffect(() => {
        const handleResize = () => {
            setContainerHeight(window.innerHeight - 176);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const storedTarget = localStorage.getItem('redirectPath') ?? "/";
        if (storedTarget !== "/") {
            navigate(storedTarget)
        }
        localStorage.removeItem("redirectPath")
    }, [navigate])

    const [isWhatModalOpen, setIsWhatModalOpen] = useState(false);
    const [isLocationEditorOpen, setIsLocationEditorOpen] = useState(false);
    const [whatSelectedValue, setWhatSelectedValue] = useState<IPosition | null>(null);
    const [whereSelectedValue, setWhereSelectedValue] = useState<ILocation | null>(null);

    const [selectedAlertOption, setSelectedAlertOption] = useState(alertOptions[0]);
    const [savedSearchItem, setSavedSearchItem] = useState<IJobDemand | null>(null);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

    const listRef = useRef<VirtualList | null>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const initialLoadRef = useRef(true);

    const handleScroll = useCallback(({scrollOffset}: { scrollOffset: number }) => {
        if (!initialLoadRef.current) {
            setScrollPosition(scrollOffset);
            sessionStorage.setItem('candidatePoolScrollPosition', scrollOffset.toString());
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchWhatIdParam = Number(params.get('search_what_id'));
        const searchWhatLabel = params.get('search_what_label');
        const searchLocationIdParam = Number(params.get('search_location_id'));
        const searchLocationLabel = params.get('search_location_label');
        const arg = {id: searchWhatIdParam, label: searchWhatLabel}

        setWhatSelectedValue(arg as IPosition)
        setWhereSelectedValue({id: searchLocationIdParam, label: searchLocationLabel} as ILocation)
    }, [location.search]);

    const {
        data,
        isFetching,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useCandidatePool(LIMIT, whatSelectedValue || undefined, whereSelectedValue || undefined);

    useEffect(() => {
        const savedScrollPosition = sessionStorage.getItem('candidatePoolScrollPosition');
        if (savedScrollPosition) {
            const parsedPosition = parseInt(savedScrollPosition, 10);
            setScrollPosition(parsedPosition);

            setTimeout(() => {
                listRef.current?.scrollTo(parsedPosition);
                initialLoadRef.current = false;
            }, 0);
        } else {
            initialLoadRef.current = false;
        }
    }, []);

    useEffect(() => {
        if (location.state?.fromCandidateDetails) {
            const savedScrollPosition = sessionStorage.getItem('candidatePoolScrollPosition');
            if (savedScrollPosition) {
                const parsedPosition = parseInt(savedScrollPosition, 10);
                setScrollPosition(parsedPosition);
                listRef.current?.scrollTo(parsedPosition);
            }
        } else {
            setScrollPosition(0);
            sessionStorage.removeItem('candidatePoolScrollPosition');
        }
    }, [location, whatSelectedValue, whereSelectedValue]);

    const {
        data: numberOfCandidate,
        isLoading
    } = useCandidateCount(whatSelectedValue || undefined, whereSelectedValue || undefined);

    const updateMutation = useMutation({
        mutationFn: (updatedData: Partial<IJobDetails>) => updateSearchById(savedSearchItem?.id ? savedSearchItem.id : 0, updatedData),
        onSuccess: () => {
            message.success('Eingabe erfolgreich gespeichert');
            setIsAlertModalOpen(false);
        },
        onError: (error) => {
            message.error(`Die Stellenausschreibung konnte nicht aktualisiert werden: ${error}`);
        },
    });

    const flattenedData = useMemo(() => {
        if (!data || (data?.pages.length === 1 && data.pages[0][0]?.id === 0)) {
            return [];
        }
        return data.pages.flatMap(page => Array.isArray(page) ? page : []).filter(el => !!el.registered_at);
    }, [data]);

    const itemCount = hasNextPage ? flattenedData.length + 1 : flattenedData.length;

    const loadMoreItems = useCallback(() => {
        if (!isFetchingNextPage) {
            return fetchNextPage().then(() => {
            });
        }
        return Promise.resolve();
    }, [isFetchingNextPage, fetchNextPage]);

    const isItemLoaded = useCallback((index: number) => {
        return !hasNextPage || index < flattenedData.length;
    }, [hasNextPage, flattenedData]);

    const onWhatLooking = () => {
        setIsWhatModalOpen(true);
    };

    const onWhereLooking = useCallback(() => {
        setIsLocationEditorOpen(true);
    }, []);

    const handleSaveProfession = (value: IPosition | null) => {
        const params = new URLSearchParams(location.search);
        if (value?.id) {
            params.set('search_what_id', value.id.toString());
            params.set('search_what_label', value.label);
        } else {
            params.delete('search_what_id');
            params.delete('search_what_label');
        }

        const queryParams = params.toString();
        navigate(`/candidates?${queryParams}`);
        setWhatSelectedValue(value);
    };

    const handleSaveLocation = useCallback((value: ILocation | null) => {
        const params = new URLSearchParams(location.search);
        if (value?.id) {
            params.set('search_location_label', value.label_client);
            params.set('search_location_id', value.id.toString());
        } else {
            params.delete('search_location_label');
            params.delete('search_location_id');
        }

        const queryParams = params.toString();
        navigate(`/candidates?${queryParams}`);
        setWhereSelectedValue(value);
    }, [navigate, location.search]);

    const onDirectWhatChange = useCallback((value: string | null) => {
        const params = new URLSearchParams(location.search);
        setWhatSelectedValue(null)
        if (!value) {
            params.delete('search_what_id');
            params.delete('search_what_label');
            setWhatSelectedValue(null);

            navigate(`/candidates?${params.toString()}`);
        }
    }, [navigate, location.search]);

    const onDirectWhereChange = useCallback((value: string | null) => {
        const params = new URLSearchParams(location.search);
        setWhereSelectedValue(null)
        if (!value) {
            params.delete('search_location_id');
            params.delete('search_location_label');
            setWhereSelectedValue(null);

            navigate(`/candidates?${params.toString()}`);
        }
    }, [navigate, location.search]);

    const handleSaveFilter = useCallback(async () => {
        if (user?.id) {
            const jobDemand = {
                type: "candidate_search",
                search_what_id: whatSelectedValue?.id || null,
                search_location_id: whereSelectedValue?.id || null
            };

            try {
                const response = await addJobDemand(jobDemand as IJobDemand);
                setSavedSearchItem(response);
                setIsAlertModalOpen(true);
            } catch (error) {
                console.error('Error saving filter:', error);
                message.error('Failed to save filter. Please try again.');
            }
        } else {
            message.error('User not logged in or missing information.');
        }
    }, [user, whatSelectedValue, whereSelectedValue]);

    const handleSaveAlertSettings = () => {
        if (savedSearchItem) {
            updateMutation.mutate({
                ...savedSearchItem,
                alert_label: selectedAlertOption.label,
                alert_config_id: selectedAlertOption.id
            });
        }
    };
    const sizeMap = useRef<{ [key: number]: number }>({});

    const setSize = useCallback((index: number, size: number) => {
        sizeMap.current = {...sizeMap.current, [index]: size};
        if (listRef.current) {
            listRef.current!.resetAfterIndex(index);
        }
    }, []);
    const getSize = (index: number) => sizeMap.current[index] || 100;

    return (
        <Wrapper>
            <SearchContainer>
                <InputWrapper>
                    <InputLabels>Was?</InputLabels>
                    <StyledInput
                        readOnly={true}
                        onClick={onWhatLooking}
                        onTouchStart={onWhatLooking}
                        placeholder={isMobile ? "Was?" : "Alle, kein Filter"}
                        value={whatSelectedValue?.label || ''}
                        prefix={isMobile ? <SearchOutlined/> : <EditOutlined/>}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onDirectWhatChange(e.target.value)}
                        suffix={
                            <StyledCloseBtn
                                onClick={() => onDirectWhatChange(null)}
                                style={{color: colors.text, backgroundColor: "white"}}
                                size="small"
                                shape="circle"
                                icon={<CloseOutlined/>}
                            />
                        }
                        size="large"
                    />
                </InputWrapper>
                <InputWrapper>
                    <InputLabels>Wo?</InputLabels>
                    <StyledInput
                        size="large"
                        onClick={onWhereLooking}
                        onTouchStart={onWhereLooking}
                        readOnly={true}
                        placeholder={isMobile ? "Wo?" : "Alle, kein Filter"}
                        value={whereSelectedValue?.label || ''}
                        prefix={isMobile ? <EnvironmentOutlined/> : <EditOutlined/>}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onDirectWhereChange(e.target.value)}
                        suffix={
                            <StyledCloseBtn
                                onClick={() => onDirectWhereChange(null)}
                                style={{color: colors.text, backgroundColor: "white"}}
                                size="small"
                                shape="circle"
                                icon={<CloseOutlined/>}
                            />
                        }
                    />
                </InputWrapper>
                <Tooltip
                    title="Wählen Sie zuerst welche Berufe oder Tätigkeiten Sie suchen, bevor Sie eine Suche speichern">
                    <SaveFilter onClick={handleSaveFilter}
                                disabled={!whatSelectedValue?.id}>
                        <BellOutlined/>Suche speichern
                    </SaveFilter>
                </Tooltip>
            </SearchContainer>
            <CandidateCount>
                Kandidatenpool: {isLoading ? (
                <Skeleton.Button active={true} style={{width: 128, height: 16}} size='small' shape='default'/>
            ) : (
                `(${numberOfCandidate?.id || 0} Kandidaten)`
            )}
            </CandidateCount>
            {isFetching && <AbsLoader><Spin size="large"/></AbsLoader>}
            <TableWrapper>
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems}
                >
                    {({onItemsRendered, ref}) => (
                        <VirtualList
                            height={containerHeight}
                            itemCount={itemCount}
                            itemSize={getSize}
                            onItemsRendered={onItemsRendered}
                            ref={(listInstance) => {
                                ref(listInstance);
                                listRef.current = listInstance;
                            }}
                            width="100%"
                            initialScrollOffset={scrollPosition}
                            onScroll={handleScroll}
                        >
                            {({index, style}) => (
                                <div style={style}>
                                    <TableRow
                                        index={index}
                                        setSize={setSize}
                                        flattenedData={flattenedData}
                                    >
                                        {() => <CandidateItem item={flattenedData[index]}/>}
                                    </TableRow>
                                </div>
                            )}
                        </VirtualList>
                    )}
                </InfiniteLoader>
            </TableWrapper>
            {isLocationEditorOpen && <AddressModal
                open={isLocationEditorOpen}
                onClose={() => setIsLocationEditorOpen(false)}
                onSave={handleSaveLocation}
                initialValue={whereSelectedValue}
            />}
            <ProfessionModal
                initialValue={whatSelectedValue?.label || ""}
                isModalOpen={isWhatModalOpen}
                handleSaveProf={handleSaveProfession}
                onClose={() => setIsWhatModalOpen(false)}
            />
            <Modal
                title="Benachrichtigung per Email"
                open={isAlertModalOpen}
                onCancel={() => setIsAlertModalOpen(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsAlertModalOpen(false)}>
                        Abbrechen
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSaveAlertSettings}>
                        Speichern
                    </Button>,
                ]}
            >
                <SearchLinkWrapper><p><CheckCircleOutlined style={{color: 'green'}}/> Eine Übersicht finden Sie unter:
                </p> <Link
                    to="/searches">{"Mein Konto > Meine Suchen"}</Link></SearchLinkWrapper>
                <p>Bestimmen Sie wie häufig wir Sie über passende neue Kandidaten informieren:</p>
                <Radio.Group
                    onChange={(e) => setSelectedAlertOption(alertOptions.find(option => option.id === e.target.value)!)}
                    value={selectedAlertOption.id}
                >
                    {alertOptions.map(option => (
                        <Radio key={option.id} value={option.id}>{option.label}</Radio>
                    ))}
                </Radio.Group>
            </Modal>
            {isPrivateUser ? <Modal
                title="Willkommen bei silvertalent"
                open={isNewUserBannerVisible}
                footer={null}
            >
                <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                    <ul>
                        <li><b>Großer Pool an erfahrenen Fachkräften</b> <br/>
                            Finden Sie qualifizierte Rentner aus allen Branchen - von Fachexperten bis zu motivierten Quereinsteigern
                        </li>
                        <li><b>Einfach und schnell</b> <br/>
                            Erstellen Sie Ihre Stellenanzeige in wenigen Minuten und erreichen Sie direkt interessierte Kandidaten
                        </li>
                        <li><b>Sofort starten</b> <br/>
                            Nach der Bezahlung wird Ihre Stelle sofort veröffentlicht und Sie können passende Bewerber kontaktieren
                        </li>
                    </ul>
                    <Button type="primary" onClick={() => {
                        setBannerVisible(false);
                        navigate('/new/job');  // or your route to job creation
                    }}>
                        Jetzt Stelle inserieren
                    </Button>
                </div>
            </Modal> : <Modal
                title="Und so einfach funktioniert silvertalent"
                open={isNewUserBannerVisible}
                footer={null}
            >
                <div style={{display: "flex", flexDirection: "column",}}>
                    <ul>
                        <li><b>Riesigen Rentnerpool nutzen</b> <br/> Wir haben Profile aller Berufsgruppen, sowohl
                            Fachkräfte als auch Hilfskräfte und Quereinsteiger
                        </li>
                        <li><b>Stellenanzeige schalten</b> <br/> Klicken Sie im Menü auf "Meine Stellen", um Ihre Stelle
                            kostenlos zu inserieren
                        </li>
                        <li><b>Tarif auswählen</b> <br/> Um Bewerbungen einzusehen passenden Tarif wählen und bequem
                            online bezahlen
                        </li>
                    </ul>
                    <Button type="primary" onClick={() => setBannerVisible(false)}>zu den
                        Kandidaten</Button>
                </div>
            </Modal>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.bg};
`

const CandidateCount = styled.div`
    font-weight: bold;
    margin: 16px 0;
`

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const AbsLoader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: calc(50% - 22px);
    top: calc(55%);
`;

export const SearchLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 16px;

    p {
        margin: 0;
    }
`;
