import React, {useContext, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Alert, Card, Col, Row, Space, Spin} from 'antd';
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ICandidateDetails} from "../../services/types.ts";
import {getCandidateByIdApi} from "../../services/api.ts";
import {GeocodedMap} from "../../components/Map.tsx";
import styled from "styled-components";
import {ContactCandidateModal} from "./ContactCandidateModal.tsx";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";
import {InfoRow} from "candidate/src/pages/messageDetail/MessageDetail.tsx";
import {AuthContext} from "../../context/AuthContext.tsx";
import {MakePaymentModal} from "../../components/MakePaymentModal.tsx";


const StyledCard = styled(Card)`
    margin-bottom: 24px;

    @media (max-width: 768px) {
        margin-bottom: 16px;
    }
`;

const MapWrapper = styled.div`
    height: 300px;

    @media (max-width: 768px) {
        height: 200px;
    }
`;

export const Candidate: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const {id = ""} = useParams<{ id: string }>();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const [isPaymentModalOpen, setPaymentModal] = useState(false)
    const {user} = useContext(AuthContext);

    const {data: candArr, isLoading, error}: UseQueryResult<ICandidateDetails[], Error> = useQuery({
        queryKey: ['candidate', id],
        queryFn: () => getCandidateByIdApi(id),
        enabled: !!id,
    });

    const data = candArr ? candArr[0] : null

    const address = useMemo(() => {
        if (data?.location) {
            return `${data.location.postal_code} ${data.display.city}, Deutschland`;
        }
    }, [data?.location, data?.display.city]);

    if (isLoading || !data) {
        return (
            <div style={{
                width: '100%',
                height: "calc(100vh - 100px)",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Spin size="large"/>
            </div>
        );
    }

    if (error) return <Alert message="Error" description={error.message} type="error"/>;
    if (!data) return <Alert message="No candidate data found" type="warning"/>;


    const mainJobItems = [
        {key: '1', label: 'Letzte Tätigkeit', value: data.main_job.job_position},
        {key: '2', label: 'Berufserfahrung', value: data.main_job.job_experience},
        {key: '3', label: 'Gewünschter Einsatzort', value: data.main_job.specialization},
        {key: '4', label: 'Weitere Branchenerfahrung', value: data.display.work_experience},
        {key: '5', label: 'Weitere Tätigkeiten vorstellbar', value: data.display.potential_jobs},
        {
            key: '6',
            label: 'Arbeitspensum',
            value: [data.preferences.work_load, data.preferences.work_type].filter(Boolean).join(', ')
        },
        {key: '7', label: 'Ehrenamtliche Tätigkeiten vorstellbar', value: data.preferences.work_honorary},
        {key: '8', label: 'Sprachen', value: data.additional.languages}
    ].filter(item => item.value !== "");

    const lastEmployerItems = [
        {key: '1', label: 'Letzter Arbeitgeber', value: data.display.last_employer.employer_name},
        {key: '2', label: 'Tätigkeit', value: data.display.last_employer.employer_department},
        {key: '3', label: 'Verweildauer', value: data.display.last_employer.employer_years}
    ].filter(item => item.value !== "");

    const previousEmployerItems = data.display?.previous_employer?.employer_department ? [
        {key: '1', label: 'Vorletzter Arbeitgeber', value: data.display.previous_employer.employer_name},
        {key: '2', label: 'Tätigkeit', value: data.display.previous_employer.employer_department},
        {key: '3', label: 'Verweildauer', value: data.display.previous_employer.employer_years}
    ].filter(item => item.value !== "") : [];

    const educationItems = [
        {key: '1', label: 'Titel des höchsten Abschlusses', value: data.education?.degree},
        {key: '2', label: 'Weitere Abschlüsse', value: data.education?.more_title},
        {key: '3', label: 'Fortbildungen', value: data.education?.trainings},
        {key: '4', label: 'Kenntnisse in relevanter Software bzw. Spezialgeräten', value: data.education?.software}
    ].filter(item => item.value !== "");

    const locationItems = [
        {key: '1', label: 'Wohnort', value: address}
    ].filter(item => item.value !== "");

    const toggleContactModal = () => {
        setIsModalOpen(prev => !prev);
    }

    return (
        <div style={{padding: isMobile ? '16px' : '24px'}}>
            <PageTitle
                title={`Anonymes Kurzprofil - Kandidat ${data.key.slice(0,6)}`}
                description={data?._outreaches_to_candidate?.outreach_status &&
                    "Kandidat kontaktiert - sie werden benachrichtigt sobald er reagiert"}
                mainBtnText={!data?._outreaches_to_candidate?.outreach_status ? "Kontaktieren" : undefined}
                onMainBtnClick={user?.plan_type === 'free' ? () => setPaymentModal(true) : !data?._outreaches_to_candidate?.outreach_status ? toggleContactModal : undefined}
            />
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <StyledCard title="Wichtigste Fakten" style={{backgroundColor: '#f8f9fb'}}>
                        {mainJobItems.filter(item => !!item.value).map(item => {
                            return <InfoRow label={item.label} value={item.value}/>
                        })}
                    </StyledCard>
                    <StyledCard title="Berufserfahrung">
                        <Space direction="vertical" size="middle" style={{width: '100%'}}>
                            {lastEmployerItems.filter(item => !!item.value).map(item => {
                                return <InfoRow label={item.label} value={item.value}/>
                            })}
                            {previousEmployerItems.length > 0 && (
                                previousEmployerItems.filter(item => !!item.value).map(item => {
                                    return <InfoRow label={item.label} value={item.value}/>
                                })
                            )}
                        </Space>
                    </StyledCard>
                    <StyledCard title="Berufsausbildung">
                        {
                            educationItems.filter(item => !!item.value).map(item => {
                                return <InfoRow label={item.label} value={item.value}/>
                            })
                        }
                    </StyledCard>
                </Col>
                <Col xs={24} md={12}>
                    <StyledCard title="Ort">
                        {
                            locationItems.filter(item => !!item.value).map(item => {
                                return <InfoRow label={item.label} value={item.value}/>
                            })
                        }
                        <MapWrapper>
                            <GeocodedMap address={address}/>
                        </MapWrapper>
                    </StyledCard>
                </Col>
            </Row>
            {data && <ContactCandidateModal
                title={`Kandidat ${data.key.slice(0,6)}`}
                jobPosition={data.main_job.job_position}
                jobExp={data.main_job.job_experience}
                location={data.location.postal_code}
                city={data?.display.city}
                candidateId={data.id}
                isModalOpen={isModalOpen}
                handleCancel={toggleContactModal}
                handleOk={toggleContactModal}
            />}
            <MakePaymentModal isPaymentModalOpen={isPaymentModalOpen} setPaymentModal={setPaymentModal} itemPath={`candidate/${data.key}`}/>
        </div>
    );
};
