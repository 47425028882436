import {useContext} from 'react';
import {Alert, List, Typography} from 'antd';
import {
    CheckCircleOutlined,
    DollarOutlined,
    ExclamationCircleOutlined,
    FileTextOutlined,
    SearchOutlined,
    StarOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {AuthContext} from "../../context/AuthContext.tsx";

const {Text} = Typography;

const menuItems = [
    {
        icon: <StarOutlined/>,
        title: "Weiterempfehlen",
        description: "Empfehlen Sie silvertalent weiter und profitieren Sie davon",
        path: "/recomendation"
    },
    {
        icon: <FileTextOutlined/>,
        title: "Vorlagen",
        description: "Managen Sie Ihre Vorlagen für die Kandidatenansprache",
        path: "/templates"
    },
    {
        icon: <DollarOutlined/>,
        title: "Finanzielles",
        description: "Überblick über Zahlungen und Ihren Tarif",
        path: "/finances"
    },
    {
        icon: <UserOutlined/>,
        title: "Zugangsdaten",
        description: "Ändern Sie Ihr Passwort oder Ihre Emailadresse",
        path: "/privacy"
    },
    {
        icon: <SearchOutlined/>,
        title: "Meine Suchen",
        description: "Managen Sie Ihre gespeicherten Suchen",
        path: "/searches"
    }
];


export const Account = () => {
    const {user} = useContext(AuthContext);
    const isPrivateUser = user?._client?.isPrivate;

    return (
        <div>
            <PageTitle title="Mein Konto"/>
            {!isPrivateUser ?<Alert
                message={user?.plan_type === "paid" ? `Sie haben aktuell ${user.jobs_current_month} Stellenanzeigen von ${user.jobs_allowed_monthly} möglichen geschaltet. Sie benötigen mehr Stellenanzeigen? Jetzt unter "Finanzielles" auf einen anderen flexiblen Plan wechseln.` : "Momentan testen Sie silvertalent im free plan und können daher noch keine Bewerbungen einsehen. Buchen Sie jetzt unter \"Finanzielles\" einen unserer flexiblen Pläne und lernen Sie unsere Kandidaten kennen."}
                type={user?.plan_type === "paid" ? "info" : "warning"}
                showIcon
                icon={user?.plan_type === "paid" ? <CheckCircleOutlined/> : <ExclamationCircleOutlined/>}
                style={{marginBottom: '20px'}}
            />:
            <Alert
                message={"Sie nutzen silvertalent derzeit als Privatperson. Um Ihre Stellenanzeige zu veröffentlichen und Bewerbungen einzusehen, ist eine einmalige Zahlung erforderlich. Veröffentlichen Sie einfach eine Stelle"}
                type={user?.plan_type === "paid" ? "info" : "warning"}
                showIcon
                icon={user?.plan_type === "paid" ? <CheckCircleOutlined/> : <ExclamationCircleOutlined/>}
                style={{marginBottom: '20px'}}
            />}

            <List
                itemLayout="horizontal"
                dataSource={menuItems}
                renderItem={(item) => (
                    <List.Item>
                        <Link to={item.path} style={{display: 'block', width: '100%'}}>
                            <List.Item.Meta
                                avatar={item.icon}
                                title={<Text strong>{item.title}</Text>}
                                description={item.description}
                            />
                        </Link>
                    </List.Item>
                )}
            />
        </div>
    );
};
