import { Steps } from "antd";
import { StepsProps } from "antd/lib/steps";

type StepStatus = 'wait' | 'process' | 'finish' | 'error';
type StepName = 'anzeige' | 'anzeigenart' | 'payment' | 'complete';

interface JobStepsProps {
    activeStep: StepName;
}

export const JobSteps = ({ activeStep }: JobStepsProps) => {
    const stepOrder: StepName[] = ['anzeige', 'anzeigenart', 'payment', 'complete'];

    const getStepStatus = (step: StepName): StepStatus => {
        const currentIndex = stepOrder.indexOf(activeStep);
        const stepIndex = stepOrder.indexOf(step);

        if (stepIndex < currentIndex) return 'finish';
        if (stepIndex === currentIndex) return 'process';
        return 'wait';
    };

    const items: StepsProps['items'] = [
        {
            title: 'Anzeige',
            status: getStepStatus('anzeige'),
        },
        {
            title: 'Anzeigenart',
            status: getStepStatus('anzeigenart'),
        },
        {
            title: 'Zahlart & Rechnungsadresse',
            status: getStepStatus('payment'),
        },
        {
            title: 'Fertig',
            status: getStepStatus('complete'),
        },
    ];

    return (
        <Steps
            style={{ marginBottom: '32px' }}
            items={items}
            current={stepOrder.indexOf(activeStep)}
        />
    );
};
