import {Link} from "react-router-dom";

export const items = [
    {
        key: "pool",
        label: <Link to="/">Kandidatenpool</Link>,
    },
    {
        key: "messages",
        label: <Link to="/messages">Nachrichten</Link>,
    },
    {
        key: "jobs",
        label: <Link to="/jobs">Meine Stellen</Link>,
    },
    {
        key: "profile",
        label: <Link to="/profile">Mein Profil</Link>,
    },
    {
        key: "account",
        label: <Link to="/account">Mein Konto</Link>,
    }
];
