import {getCandidateCount, getCandidatePool} from "../../../services/api";
import {CandidatePoolType, ILocation, IPosition} from "../../../services/types";
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";


export const useCandidatePool = (limit: number, position?: IPosition, location?: ILocation) => {
    return useInfiniteQuery<CandidatePoolType, Error>({
        initialData: undefined,
        initialPageParam: undefined,
        queryKey: ['candidatePool', position?.id || 'all', location?.id || 'all'],
        queryFn: ({pageParam = 0}) => getCandidatePool({
            limit,
            offset: pageParam as number,
            position,
            location
        }),
        getNextPageParam: (lastPage, allPages) => {
            const nextOffset = allPages.length * limit;
            const lastPageLength = lastPage[lastPage.length - 1].id === -1 ? lastPage.length - 1 : lastPage.length;
            return lastPageLength === limit ? nextOffset : undefined;

        },
        staleTime: 5 * 60 * 1000,
    });
};

export const useCandidateCount = (position?: IPosition, location?: ILocation) => {
    return useQuery({
        queryKey: ['candidateCount', position?.id || "all", location?.id || "all"],
        queryFn: () => getCandidateCount(position, location)
    });
};
