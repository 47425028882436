import {Typography} from 'antd';
import styled from 'styled-components';
import {LikeOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {IMessage} from "../../../services/types.ts";
import {useContext, useState} from "react";
import {AuthContext} from "../../../context/AuthContext.tsx";
import {useNavigate} from "react-router-dom";
import {BtnStyledMessageItem} from "candidate/src/pages/messages/components/MessageItem.tsx";
import {
    BgWrapper,
    InfoContainer,
    ItemBtns,
    ItemDetails,
    RowItemWrapper
} from "candidate/src/pages/jobPool/components/JobPoolItem.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";
import {MakePaymentModal} from "../../../components/MakePaymentModal.tsx";

const {Text, Title} = Typography;

interface IMessageItemProps {
    item: IMessage;
}

export const MessageItem: React.FC<IMessageItemProps> = ({item}) => {
    const [isPaymentModalOpen, setPaymentModal] = useState(false)
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const formatDate = (dateString: number) => {
        return new Date(dateString).toLocaleDateString('de-DE');
    };

    const viewCandidateProfile = () => {
        navigate(`/details/${item.key}`);
    }
    const viewCandidateMessages = () => {
        user!.plan_type === "paid" ? navigate(`/messages/${item.key}`) : setPaymentModal(true)
    }

    return (
        <RowItemWrapper>
            <BgWrapper style={{marginBottom: isMobile ? 12 : 0}}>
                <ItemDetails style={{width: "100%"}}>
                    <InfoContainer>
                        <ContentWrapper>
                            <div style={{display: "flex"}}>
                                <IconWrapper>
                                    {item.type === "application" ?
                                        <LikeOutlined style={{fontSize: '24px', color: '#1890ff'}}/> :
                                        <QuestionCircleOutlined style={{fontSize: '24px', color: '#1890ff'}}/>}
                                </IconWrapper>
                                <Title level={5}
                                       style={{margin: 0}}>{item.type === "application" ? "Bewerbung auf eine Stellenanzeige" : "Direktansprache eines Kandidaten"}</Title>
                            </div>
                            <Text>Stelle: {item?._job_demand_with_labels[0]?.title ? item?._job_demand_with_labels[0].title : 'Keine Stelle angehängt oder die Stelle wurde gelöscht'}</Text>
                            {item._candidate_2_0 && item.contact_data === "visible" && user?.plan_type === "paid" &&
                                <Text>Kandidat: {item._candidate_2_0.contact.first_name} {item._candidate_2_0.contact.last_name} {item._candidate_2_0.contact.email} {item._candidate_2_0.contact.phone}</Text>}
                            {item._candidate_2_0 && user?.plan_type === "paid" && item.contact_data !== "visible" &&
                                <Text>Anonymer
                                    Kandidat: {`${item._candidate_2_0?.main_job?.job_position || ""}, ${item._candidate_2_0?.main_job?.job_experience || ""}`}</Text>}
                            {!item?._candidate_2_0?.id && <Text>Anonymer
                                Kandidat: Der Kandidat hat seine Jobsuche abgeschlossen</Text>}
                            {user?.plan_type === "paid" && <Text>Nachricht: {item.message}</Text>}
                            <Text>Kontaktaufnahme: {formatDate(item.created_at)}</Text>
                        </ContentWrapper>
                    </InfoContainer>
                    <ItemBtns>
                        <BtnStyledMessageItem type="primary" onClick={viewCandidateMessages}>
                            Gesprächsverlauf
                        </BtnStyledMessageItem>
                        <BtnStyledMessageItem onClick={viewCandidateProfile}>
                            Zum Kandidatenprofil
                        </BtnStyledMessageItem>
                    </ItemBtns>
                </ItemDetails>
                <MakePaymentModal isPaymentModalOpen={isPaymentModalOpen} setPaymentModal={setPaymentModal} itemPath={`/messages/${item.key}`}/>
            </BgWrapper>
        </RowItemWrapper>
    );
};

const IconWrapper = styled.div`
    margin-right: 16px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
