import {Modal, notification} from "antd";
import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {AuthContext} from "../context/AuthContext.tsx";
import {getToken} from "../util/tokenCookies.ts";
import {getStripeUrl} from "../services/api.ts";

interface IMakePaymentModal {
    isPaymentModalOpen: boolean
    setPaymentModal: (v: boolean) => void
    itemPath: string
}

export const MakePaymentModal = ({isPaymentModalOpen, setPaymentModal, itemPath}: IMakePaymentModal) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const isPrivateUser = user?._client?.isPrivate;

    const handleOk = async () => {
        setLoading(true);
        if (isPrivateUser) {
            const authToken = getToken();

            const stripeResponse = await getStripeUrl({id: authToken, product: "Private", redirectPath: itemPath});
            if (stripeResponse.url) {
                window.location.href = stripeResponse.url;
            } else {
                notification.error({message: "Es ist ein Fehler beim Anlegen der Stellenanzeige aufgetreten."});
            }
            setLoading(false)
            return
        } else {
            setLoading(false)
            navigate('/finances')
        }

    }

    return <Modal
        title={isPrivateUser
            ? "Einmalige Zahlung für Ihre Stellenanzeige"
            : "Buchen Sie jetzt einen kostenpflichtigen Plan"}
        open={isPaymentModalOpen}
        onOk={handleOk}
        onCancel={() => setPaymentModal(false)}
        okText={isPrivateUser ? "Jetzt bezahlen" : "Jetzt Plan buchen"}
        cancelText="Zurück"
        okButtonProps={{loading: loading}}
    >
        {isPrivateUser ? (
            <p>Um mit Bewerbern in Kontakt zu treten, tätigen Sie bitte eine einmalige Zahlung von 59€ für Ihre
                Stellenanzeige.</p>
        ) : (
            <p>Sie benutzen aktuell noch die kostenlose Testversion von silvertalent. Wechseln Sie jetzt in einen
                kostenpflichtigen Plan, um mit Bewerbern in Kontakt zu treten.</p>
        )}
    </Modal>
}
