import {useContext, useState} from 'react';
import {Alert, Button, Input, message, Modal, Popconfirm, Select, Spin} from "antd";
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {addOutreach, addOutreachTemplate, getAllOutreachTemplates, getJobDemand} from "../../services/api.ts";
import {OutreachRequest, OutreachTemplate} from "../../services/types.ts";
import styled from "styled-components";
import {FolderAddOutlined, HeartOutlined} from "@ant-design/icons";
import {AuthContext} from "../../context/AuthContext.tsx";
import {format} from "date-fns";
import {Link} from "react-router-dom";

const {TextArea} = Input;

interface IContactCandidateModal {
    title: string;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    jobPosition: string;
    jobExp: string;
    location?: string;
    city?: string;
    candidateId: number;
}

export const ContactCandidateModal = ({
                                          title,
                                          isModalOpen,
                                          handleCancel,
                                          city,
                                          jobExp,
                                          jobPosition,
                                          location,
                                          candidateId
                                      }: IContactCandidateModal) => {
    const [selectedTemplate, setSelectedTemplate] = useState<OutreachTemplate | null>(null);
    const [selectedDemand, setSelectedDemand] = useState<{ id: number, title: string } | null>(null);
    const [outreachMessage, setOutReachMessage] = useState<string>('');
    const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
    const [templateTitle, setTemplateTitle] = useState(() => {
        const currentDate = format(new Date(), 'dd.MM.yyyy');
        return `Vorlage ${currentDate}`;
    });
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [messageError, setMessageError] = useState(false);

    const {user} = useContext(AuthContext);
    const queryClient = useQueryClient();


    const {data: templates = [], isLoading: isLoadingTemplates, error: templatesError} = useQuery<OutreachTemplate[]>({
        queryKey: ['outreachTemplates'],
        queryFn: getAllOutreachTemplates,
    });

    const {data: jobDemands = [], isLoading: isLoadingJobDemands, error: jobDemandsError} = useQuery({
        queryKey: ['jobDemands'],
        queryFn: () => getJobDemand({mode: "dropdown"}),
    });

    const addTemplateMutation = useMutation({
        mutationFn: addOutreachTemplate,
        onSuccess: () => {
            message.success('Vorlage erfolgreich gespeichert');
            queryClient.invalidateQueries({queryKey: ['outreachTemplates']});
            setSaveTemplateOpen(false);
            setConfirmLoading(false);
            setTemplateTitle('');
        },
        onError: (error) => {
            console.error('Error saving template:', error);
            message.error('Failed to save template');
            setConfirmLoading(false);
        },
    });

    const addOutreachMutation = useMutation({
        mutationFn: addOutreach,
        onSuccess: () => {
            message.success('Nachricht erfolgreich gesendet');
            queryClient.invalidateQueries({queryKey: ['outreaches']});
            handleCancel();
            setSelectedDemand(null);
            setOutReachMessage('');
            setSelectedTemplate(null);
        },
        onError: (error) => {
            console.error('Error saving outreach:', error);
            message.error('Failed to save outreach');
        },
    });

    const handleTemplateChange = (value: number) => {
        const template = templates.find(t => t.id === value);
        setOutReachMessage(template?.message || '');
        setSelectedTemplate(template || null);
    };
    const handleDemandChange = (value: number) => {
        const demand = jobDemands.find(t => t.id === value);
        setSelectedDemand(demand || null);
    };

    const handleSaveTemplate = () => {
        if (!templateTitle.trim()) {
            message.warning('Bitte geben Sie einen Titel für die Vorlage ein');
            return;
        }

        setConfirmLoading(true);
        const newTemplate = {
            name: templateTitle.trim(),
            message: outreachMessage,
            users_id: user?.id
        };

        addTemplateMutation.mutate(newTemplate);
    };

    const handleCancelSaveTemplate = () => {
        setSaveTemplateOpen(false);
        setTemplateTitle('');
    };

    const showSaveTemplatePopconfirm = () => {
        if (!outreachMessage) {
            message.warning('Bitte geben Sie eine Nachricht ein, bevor Sie die Vorlage speichern');
            return;
        }
        setSaveTemplateOpen(true);
    };

    const handleContact = () => {
        let hasError = false;
        if (!outreachMessage) {
            setMessageError(true);
            hasError = true;
        }

        if (!user?.client_id || !user?.id || hasError) {
            message.warning('Bitte füllen Sie die erforderlichen Felder aus');
            return;
        }

        const outreachData: OutreachRequest = {
            message: outreachMessage,
            candidate_id: candidateId,
            client_id: Number(user.client_id),
            outreach_status_id: 1,
            job_demands_id: selectedDemand?.id ?? null,
            outreach_template_id: selectedTemplate?.id || undefined,
            users_id: user.id,
        };

        addOutreachMutation.mutate(outreachData);
    };
    const isLoading = isLoadingTemplates || isLoadingJobDemands;
    const hasError = templatesError || jobDemandsError;

    return (
        <Modal
            title={title}
            style={{top: 32}}
            width={700}
            open={isModalOpen}
            onCancel={handleCancel}
            onOk={handleContact}
            cancelText="Zurück"
            okText="Kontaktieren"
            okButtonProps={{loading: addOutreachMutation.isPending}}
        >
            {isLoading ? (
                <LoadingWrapper>
                    <Spin size="large"/>
                    <p>Loading...</p>
                </LoadingWrapper>
            ) : hasError ? (
                <ErrorMessage>Error loading data</ErrorMessage>
            ) : (
                <>
                    <TitleHeader>
                        <JobLocPosDetails>
                            <p>{jobPosition}, {jobExp}</p>
                            <p>{location}, {city}</p>
                        </JobLocPosDetails>
                        <Select
                            placeholder="Nachrichtenvorlagen"
                            onChange={handleTemplateChange}
                            value={selectedTemplate?.id}
                            options={templates.map(template => ({
                                value: template.id,
                                label: template.name
                            }))}
                        />
                    </TitleHeader>
                    <SaveTemplateWrapper>
                        <p>Nachricht</p>
                        <Popconfirm
                            title=""
                            description={
                                <Input
                                    size="large"
                                    placeholder="Enter template title"
                                    value={templateTitle}
                                    onChange={(e) => setTemplateTitle(e.target.value)}
                                />
                            }
                            open={saveTemplateOpen}
                            onConfirm={handleSaveTemplate}
                            okButtonProps={{loading: confirmLoading}}
                            onCancel={handleCancelSaveTemplate}
                            okText="Speichern"
                            icon={null}
                            cancelText="Abbrechen"
                        >
                            <Button
                                size="small"
                                icon={<HeartOutlined/>}
                                onClick={showSaveTemplatePopconfirm}
                            >
                                Als Vorlage speichern
                            </Button>
                        </Popconfirm>
                    </SaveTemplateWrapper>
                    <TextArea
                        rows={10}
                        style={{marginBottom: '16px'}}
                        value={outreachMessage}
                        onChange={(e) => {
                            setOutReachMessage(e.target.value);
                            if (e.target.value) setMessageError(false);
                        }}
                        placeholder="Stellen Sie sich hier kurz dem Kandidaten vor und beschreiben Sie die Position..."
                        status={messageError ? "error" : ""}
                    />
                    <Select
                        style={{width: '100%', marginBottom: '1rem'}}
                        placeholder="Stellenanzeige auswählen"
                        onChange={(value) => {
                            handleDemandChange(value);
                        }}
                        value={selectedDemand?.id}
                        disabled={jobDemands.length===0}
                        options={jobDemands.map(jd => ({
                            value: jd.id,
                            label: jd.title
                        }))}
                    />
                    <Link to="/new/job"><Button icon={<FolderAddOutlined/>} style={{marginBottom: 16}} size="small">Neue Stelle anlegen</Button></Link>
                    <Alert
                        message="Wir empfehlen eine Stellenanzeige anzulegen und auszuwählen, bevor Sie einen Kandidaten kontaktieren. Damit können dem Kandidaten alle nötigen Informationen zur Stelle angezeigt werden."
                        type="info" showIcon/>
                    {user?.plan_type !== "paid" && <Alert
                        style={{marginTop: 16}}
                        message="Sie testen silvertalent gerade kostenlos und können Kandidaten kontaktieren. Um Antworten sehen zu können, müssen Sie einen kostenpflichtigen Plan buchen. Jegliche externe Kontaktdaten werden daher aus Ihren Nachrichten und Stellenanzeigen entfernt."
                        type="info" showIcon/>}
                </>
            )}
        </Modal>
    );
};

const TitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`

const SaveTemplateWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;

    p {
        margin: 0;
    }
`

const JobLocPosDetails = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    p {
        margin: 0;
    }
`

const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
`

const ErrorMessage = styled.div`
    color: red;
    text-align: center;
    padding: 20px;
`
