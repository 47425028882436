import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import React from "react";

Bugsnag.start({
    apiKey: 'bdc43d622dc1bed8e9349630fedd105d',
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.VITE_ENVIRONMENT,
})

BugsnagPerformance.start({ apiKey: 'bdc43d622dc1bed8e9349630fedd105d' })

export const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)
