import {useContext, useEffect} from 'react';
import {Alert, Col, Form, Input, Row, Select} from 'antd';
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {useMutation, useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {getClientProfile, getCompanySize, updateClientProfile} from "../../services/api.ts";
import {CompanySizeType, IClientProfile} from "../../services/types.ts";
import {AuthContext} from "../../context/AuthContext.tsx";
import {useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery} from "@uidotdev/usehooks";

const {Option} = Select;

const PROFILE_DESC_INITIAL = "[Tipp: Geben Sie hier in 3-5 Sätzen eine aussagekräftige Beschreibung Ihres Unternehmens oder Haushalts ein]\n" +
    "\n" +
    "Beispiel: Wir sind eine kleine Traditionsfleischerei in Berlin Mitte. Seit über 100 Jahren versorgen wir unsere Kunden mit frischester Ware. Wir sind 7 Mitarbeiter und haben sehr viel Freude bei unserer Arbeit. Unser Grillfest im Sommer ist legendär."
export const CreateProfile = () => {
    const {user} = useContext(AuthContext)
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const {data: profile, isFetching} = useQuery<IClientProfile>({
        queryKey: ['clientProfile'],
        queryFn: () => {
            if (user?.client_id) {
                return getClientProfile(user.client_id);
            }
            throw new Error("Client ID is undefined");
        },
    });

    useEffect(() => {
        if (user?._client?.isPrivate || !!user?._client?.profile_name) {
            navigate("/new/job");
        }
    },[navigate, user?._client?.isPrivate, user?._client?.profile_name])

    useEffect(() => {
        if (profile) {
            form.setFieldsValue({
                profile_name: profile.profile_name,
                salutation_id: profile.salutation_id === 0 ? undefined : profile.salutation_id,
                contact_name: profile.contact_name,
                employer_type_id: 1,
                profile_company_size: profile.profile_company_size === 0 ? undefined : profile.profile_company_size,
                profile_description: profile.profile_description || PROFILE_DESC_INITIAL,
            });
        }
    }, [profile, form]);

    const mutation = useMutation({
        mutationFn: (updatedData: IClientProfile) => {
            if (user?.client_id) {
                return updateClientProfile(user.client_id, updatedData);
            }
            throw new Error("Client ID is undefined");
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({queryKey: ['user']}),
                queryClient.invalidateQueries({queryKey: ['clientProfile']})
            ]);
            await new Promise(resolve => setTimeout(resolve, 100));

            navigate(searchParams.get('from') === 'finances' ? '/finances' : `/new/job?from=profile`);
        },
    });

    const {data: companySize}: UseQueryResult<CompanySizeType, Error> = useQuery({
        queryKey: ['companySize'],
        queryFn: () => getCompanySize(),
    })

    const onFinish = (values: {
        profile_name: string;
        salutation_id: number;
        contact_name: string;
        employer_type: number;
        profile_company_size: number;
        profile_description: string
    }) => {
        const updatedProfile: IClientProfile = {
            ...profile,
            ...values,
        } as IClientProfile
        mutation.mutate(updatedProfile);
    };

    return (
        <div className="p-6">
            <PageTitle
                title={searchParams.get('from') === 'finances' ? "Arbeitgeberprofil" : "1 von 2: Stelle anlegen - Arbeitgeberprofil"}
                mainBtnText="Weiter"
                isLoading={isFetching}
                onMainBtnClick={() => form.submit()}
            />
            <Row>
                <Col span={12}>
                    <Alert showIcon message="Sie haben bisher noch kein vollständiges Arbeitgeberprofil angelegt. Machen Sie unten die
                            wichtigsten Angaben. Sie können Ihr Arbeitgeberprofil jederzeit unter 'Mein Profil' anpassen."/>
                </Col>
            </Row>

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                style={{marginTop: 16}}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="profile_name"
                            label="Arbeitgebername"
                            rules={[{required: true, message: 'Bitte geben Sie den Namen Ihres Unternehmens ein'}]}
                        >
                            <Input size={isMobile ? "large" : "middle"}
                                   placeholder="Name Ihres Unternehmens oder Ihres Haushaltes"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Ansprechpartner">
                            <Input.Group compact>
                                <Form.Item
                                    name='salutation_id'
                                    noStyle
                                    rules={[{required: true, message: 'Anrede ist erforderlich'}]}
                                >
                                    <Select style={{width: '20%'}} placeholder="Anrede">
                                        <Option value={1}>Herr</Option>
                                        <Option value={2}>Frau</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='contact_name'
                                    noStyle
                                    rules={[{required: true, message: 'Name ist erforderlich'}]}
                                >
                                    <Input size={isMobile ? "large" : "middle"} style={{width: '80%'}}
                                           placeholder="Name des Ansprechpartners"/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="profile_company_size"
                            label="Unternehmensgröße"
                            rules={[{required: true, message: 'Unternehmensgröße auswählen'}]}
                        >
                            <Select placeholder="Unternehmensgröße auswählen">
                                {companySize?.map((size) => (
                                    <Option key={size.id} value={size.id}>{size.size}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="profile_description"
                            initialValue={PROFILE_DESC_INITIAL}
                            label="Beschreibung des Arbeitgebers (bitte anpassen)"
                            rules={[{required: true, message: 'Bitte geben Sie eine Beschreibung ein'}]}
                        >
                            <Input.TextArea
                                rows={6}
                                placeholder="Beschreiben Sie Ihr Unternehmen oder Ihren Haushalt"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
