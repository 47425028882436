import {createContext, useEffect, useState, ReactNode, FC} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { createSessionCookies, getToken, removeSessionCookies } from '../util/tokenCookies';
import {
    authApi,
    getClientProfile,
    getUserApi,
    getUserDetails,
    loginUserApi,
    signupUserApi
} from '../services/api';
import { setAuthorizationHeader } from '../services/interceptors';
import { IClientProfile } from '../services/types';

export interface User {
    id?: number;
    email: string;
    privateJobsAllowed?: number;
    role?: string;
    permissions: string[];
    client_id: number;
    plan_type?: string;
    jobs_allowed?: string;
    jobs_current_month: number;
    jobs_allowed_monthly: number;
    display_plan_label?: string;
    plan_label?: string;
    _client?: IClientProfile;
}

export interface SignInCredentials {
    email: string;
    password: string;
}

export interface SignUpCredentials extends SignInCredentials {
    telephone: string;
    isPrivate: boolean;
}

export interface AuthContextData {
    user?: User;
    isAuthenticated: boolean;
    loadingUserData: boolean;
    signIn: (credentials: SignInCredentials | { token: string }) => Promise<void>;
    signUp: (credentials: SignUpCredentials) => Promise<void>;
    signOut: () => void;
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
    isNewUserBannerVisible: boolean;
    setBannerVisible: (isVisible: boolean) => void;
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | undefined>();
    const [isNewUserBannerVisible, setBannerVisible] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const queryClient = useQueryClient();

    const token = getToken();
    const isAuthenticated = Boolean(token);

    const {
        data: userData,
        isLoading: loadingUserData
    } = useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            if (!token) return null;

            setAuthorizationHeader({ request: authApi.defaults, token: token });
            const response = await getUserApi();

            if (response) {
                const { id } = response;
                const userDetails: User = await getUserDetails(id);
                const profileInfo = await getClientProfile(userDetails.client_id);
                return { ...userDetails, _client: profileInfo };
            }

            return null;
        },
        enabled: !!token,
    });

    useEffect(() => {
        if (userData) {
            setUser(userData);
        }
    }, [userData]);

    // Sign In Mutation
    const signInMutation = useMutation({
        mutationFn: async (params: SignInCredentials | { token: string }) => {
            let authToken: string;

            if ('email' in params && 'password' in params) {
                const { email, password } = params;
                const response = await loginUserApi(email, password);
                authToken = response.authToken;
            } else {
                authToken = params.token;
            }

            createSessionCookies({ authToken });
            setAuthorizationHeader({ request: authApi.defaults, token: authToken });
            return authToken;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });

    // Sign Up Mutation
    const signUpMutation = useMutation({
        mutationFn: async ({ email, password, telephone, isPrivate }: SignUpCredentials) => {
            const response = await signupUserApi(email, password, telephone, isPrivate);
            return response.authToken;
        },
        onSuccess: (authToken) => {
            createSessionCookies({ authToken });
            setAuthorizationHeader({ request: authApi.defaults, token: authToken });
            queryClient.invalidateQueries({ queryKey: ['user'] });
            setBannerVisible(true);
        },
    });

    // Authentication methods
    const signIn = async (params: SignInCredentials | { token: string }) => {
        try {
            await signInMutation.mutateAsync(params);
        } catch (error) {
            throw error as AxiosError;
        }
    };

    const signUp = async (params: SignUpCredentials) => {
        try {
            await signUpMutation.mutateAsync(params);
        } catch (error) {
            throw error as AxiosError;
        }
    };

    const signOut = () => {
        localStorage.removeItem('redirectPath')
        removeSessionCookies();
        setUser(undefined);
        queryClient.clear();
        navigate('/');
    };

    useEffect(() => {
        if (!token) {
            removeSessionCookies();
            setUser(undefined);
        }
    }, [navigate, pathname, token]);

    const contextValue: AuthContextData = {
        isAuthenticated,
        user,
        setUser,
        loadingUserData,
        signIn,
        signOut,
        signUp,
        isNewUserBannerVisible,
        setBannerVisible,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
