import React from 'react';
import { Button, Modal } from 'antd';
import styled from "styled-components";

interface ModalComponentProps {
    title: string;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    children: React.ReactNode;
    footerValue: React.ReactNode;
}

export const ModalComponent: React.FC<ModalComponentProps> = ({ title, open, onClose, onSave, children, footerValue }) => {
    const [loading] = React.useState<boolean>(false);

    const handleSave = () => {
        onSave();
        onClose();
    };

    return (
        <Modal
            title={title}
            style={{ top: 32 }}
            width={700}
            footer={
                ()=>{
                    return <ModalFooter>
                        <div>{footerValue}</div>
                        <Button type="primary" onClick={handleSave} loading={loading} style={{alignSelf: 'flex-end'}}>
                            Speichern
                        </Button>
                    </ModalFooter>
                }
            }
            open={open}
            onCancel={onClose}
        >
            {children}
        </Modal>
    );
};

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
`;
