import React, {useContext, useEffect, useState} from "react";
import {AuthContext, SignInCredentials, SignUpCredentials} from "../../../context/AuthContext.tsx";
import {useStyles} from "./useLoginStyles.ts";
import {Grid} from "antd";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";

const {useBreakpoint} = Grid;

export const useLogin = (initialIsSignup: boolean = false) => {
    const[hasRedirect, setHasRedirect] = useState(true)
    const [isSignup, setIsSignup] = useState(initialIsSignup);
    const {signIn, signUp} = useContext(AuthContext);
    const screens = useBreakpoint();
    const [error, setError] = useState<string | null>(null);
    const styles: { [key: string]: React.CSSProperties } = useStyles(screens);
    const navigate = useNavigate();

    useEffect(() => {
        const storedTarget = localStorage.getItem('redirectPath');
        if(storedTarget){
            setHasRedirect(true)
        }else {
            setTimeout(()=>setHasRedirect(false), 300)
        }
    }, [])

    const onSubmit = async (values: SignInCredentials | SignUpCredentials) => {
        localStorage.removeItem("redirectPath")
        if (isSignup) {
            await signUp(values as SignUpCredentials);
        } else {
            try {
                setError(null);
                await signIn(values);
                navigate("/");
            } catch (err) {
                if (err instanceof AxiosError && err.response?.status === 401) {
                    setError("Ungültige E-Mail oder ungültiges Passwort. Bitte versuchen Sie es erneut.");
                } else {
                    setError("Ein Fehler ist aufgetreten. Versuchen Sie es später noch einmal.");
                }
            }
        }
    };

    const toggleSignup = () => {
        const newIsSignup = !isSignup;
        setIsSignup(newIsSignup);

        if (newIsSignup) {
            navigate('/signup', { replace: true });
        } else {
            navigate('/login', { replace: true });
        }
    };

    return {
        isSignup,
        onSubmit,
        toggleSignup,
        styles,
        error,
        hasRedirect
    }
}
