import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {PrivateRoute} from "./ProtectedRoute.tsx";
import {PublicRoute} from "./PublicRoute.tsx";
import {Login} from "../pages/login/Login.tsx";
import {CandidatePool} from "../pages/candidatePool/CandidatePool.tsx";
import {Candidate} from "../pages/candidateDetail/Candidate.tsx";
import {NewJobDemand} from "../pages/newJobDemand/NewJobDemand.tsx";
import {Jobs} from "../pages/jobs/Jobs.tsx";
import {JobDetails} from "../pages/jobDetails/JobDetails.tsx";
import {MyProfile} from "../pages/profile/MyProfile.tsx";
import {Messages} from "../pages/messages/Messages.tsx";
import {CandidateMsgDetails} from "../pages/candidateMsgDetails/CandidateMsgDetails.tsx";
import {MessageDetail} from "../pages/messageDetail/MessageDetail.tsx";
import {Account} from "../pages/account/Account.tsx";
import {Recommendation} from "../pages/recomentation/Recomendation.tsx";
import {Templates} from "../pages/templates/Templates.tsx";
import {Finances} from "../pages/finances/Finances.tsx";
import {Privacy} from "../pages/privacy/Privacy.tsx";
import {Searches} from "../pages/searches/Searches.tsx";
import {EditSearch} from "../pages/editSearch/EditSearch.tsx";
import {CreateProfile} from "../pages/createProfile/CreateProfile.tsx";
import {MagicLinkRedirect} from "../pages/magicLink/MagicLinkRedirect.tsx";
import {ErrorState} from "../components/ErrorState.tsx";


export const AppRouter = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const param2 = searchParams.get('param2');
    const target = searchParams.get('target');
    const id = searchParams.get('id');

    if (target === '59mvbeluxu3ah21rhp6ndyyy3') {
        return <Navigate to="/signup" />;
    }
    if (target === '9qorkovfiichrwpol3dlx2zp2') {
        return <Navigate to="/jobs" />;
    }

    return (
        <Routes>
            {(param2 || id) && target ? (
                <Route path="*" element={<MagicLinkRedirect/>}/>
            ) : (
                <>
                    <Route
                        path="/redirects/:type"
                        element={<MagicLinkRedirect/>}
                    />
                    <Route
                        path={"/"}
                        element={
                            <PrivateRoute redirectTo={"/login"}>
                                <CandidatePool/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path={"/login"}
                        element={
                            <PublicRoute>
                                <Login/>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path={"/signup"}
                        element={
                            <PublicRoute>
                                <Login isSignup={true} />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/candidate/:id"
                        element={
                            <PrivateRoute>
                                <Candidate/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/jobs"
                        element={
                            <PrivateRoute>
                                <Jobs/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/jobs/:id"
                        element={
                            <PrivateRoute>
                                <JobDetails/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/candidates"
                        element={
                            <PrivateRoute>
                                <CandidatePool/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <PrivateRoute>
                                <MyProfile/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/messages"
                        element={
                            <PrivateRoute>
                                <Messages/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/details/:id"
                        element={
                            <PrivateRoute>
                                <CandidateMsgDetails/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/messages/:id"
                        element={
                            <PrivateRoute>
                                <MessageDetail/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account"
                        element={
                            <PrivateRoute>
                                <Account/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/recomendation"
                        element={
                            <PrivateRoute>
                                <Recommendation/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/templates"
                        element={
                            <PrivateRoute>
                                <Templates/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/finances"
                        element={
                            <PrivateRoute>
                                <Finances/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/privacy"
                        element={
                            <PrivateRoute>
                                <Privacy/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/searches"
                        element={
                            <PrivateRoute>
                                <Searches/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/searches/:id"
                        element={
                            <PrivateRoute>
                                <EditSearch/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/new/job"
                        element={
                            <PrivateRoute>
                                <NewJobDemand/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/new/profile"
                        element={
                            <PrivateRoute>
                                <CreateProfile/>
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<ErrorState/>}/>
                </>)
            }
        </Routes>
    )
}

