import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {VariableSizeList as VirtualList} from 'react-window';
import InfiniteLoader from "react-window-infinite-loader";
import {Spin} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {useJobs} from './hooks/useJobs';
import {AuthContext} from "../../context/AuthContext.tsx";
import {JobItem} from "./components/JobItem.tsx";
import {AbsLoader} from "../candidatePool/CandidatePool.tsx";
import {TableRow} from "../candidatePool/TableRow.tsx";
import {TableWrapper} from "candidate/src/pages/jobPool/JobPool.tsx";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";

const CONTAINER_HEIGHT = window.innerHeight - 133;
const LIMIT = 20;

export const Jobs: React.FC = () => {
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isPrivateUser = user?._client?.isPrivate;

    const {data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage} = useJobs(LIMIT);

    const listRef = useRef<VirtualList | null>(null);
    const sizeMap = useRef<{ [key: number]: number }>({});
    const [scrollPosition, setScrollPosition] = useState(0);
    const initialLoadRef = useRef(true);

    const flattenedData = useMemo(() => {
        if (!data || (data.pages.length === 1 && data.pages[0][0]?.id === 0)) {
            return [];
        }
        return data.pages.flatMap(page => Array.isArray(page) ? page : []);
    }, [data]);

    const itemCount = hasNextPage ? flattenedData.length + 1 : flattenedData.length;

    const loadMoreItems = useCallback(() => {
        if (!isFetchingNextPage) {
            return fetchNextPage().then(() => {
            });
        }
        return Promise.resolve();
    }, [isFetchingNextPage, fetchNextPage]);

    const isItemLoaded = useCallback((index: number) => {
        return !hasNextPage || index < flattenedData.length;
    }, [hasNextPage, flattenedData]);

    const setSize = useCallback((index: number, size: number) => {
        sizeMap.current = {...sizeMap.current, [index]: size};
        if (listRef.current) {
            listRef.current!.resetAfterIndex(index);
        }
    }, []);

    const getSize = (index: number) => sizeMap.current[index] || 129;

    const handleScroll = useCallback(({scrollOffset}: { scrollOffset: number }) => {
        if (!initialLoadRef.current) {
            setScrollPosition(scrollOffset);
            sessionStorage.setItem('jobsScrollPosition', scrollOffset.toString());
        }
    }, []);

    useEffect(() => {
        const savedScrollPosition = sessionStorage.getItem('jobsScrollPosition');
        if (savedScrollPosition) {
            const parsedPosition = parseInt(savedScrollPosition, 10);
            setScrollPosition(parsedPosition);

            setTimeout(() => {
                listRef.current?.scrollTo(parsedPosition);
                initialLoadRef.current = false;
            }, 0);
        } else {
            initialLoadRef.current = false;
        }
    }, []);

    useEffect(() => {
        if (location.state?.fromJobDetails) {
            const savedScrollPosition = sessionStorage.getItem('jobsScrollPosition');
            if (savedScrollPosition) {
                const parsedPosition = parseInt(savedScrollPosition, 10);
                setScrollPosition(parsedPosition);
                listRef.current?.scrollTo(parsedPosition);
            }
        } else {
            setScrollPosition(0);
            sessionStorage.removeItem('jobsScrollPosition');
        }
    }, [location]);

    return (
        <div>
            <PageTitle
                mainBtnType="default"
                description={`${user?.jobs_current_month ?? 0} von ${isPrivateUser ? user?.privateJobsAllowed : user?.jobs_allowed_monthly ?? 0} möglichen Stellenanzeigen
                        veröffentlicht`}
                title="Meine Stellen"
                mainBtnText="Stellenanzeige
                        erstellen"
                onMainBtnClick={() => navigate(user?._client?.profile_name ? "/new/job" : "/new/profile")}
            />
            {isFetching && <AbsLoader><Spin size="large"/></AbsLoader>}
            <TableWrapper>
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems}
                >
                    {({onItemsRendered, ref}) => (
                        <VirtualList
                            height={CONTAINER_HEIGHT}
                            itemCount={itemCount}
                            itemSize={getSize}
                            onItemsRendered={onItemsRendered}
                            ref={(listInstance) => {
                                ref(listInstance);
                                listRef.current = listInstance;
                            }}
                            width="100%"
                            initialScrollOffset={scrollPosition}
                            onScroll={handleScroll}
                        >
                            {({index, style}) => (
                                <div style={style}>
                                    <TableRow
                                        index={index}
                                        setSize={setSize}
                                        flattenedData={flattenedData}
                                    >
                                        {() => <JobItem
                                            key={flattenedData[index]?.id || index}
                                            item={flattenedData[index]}
                                            onEdit={() => navigate(`/jobs/${flattenedData[index].id}`)}
                                        />}
                                    </TableRow>
                                </div>
                            )}
                        </VirtualList>
                    )}
                </InfiniteLoader>
            </TableWrapper>
        </div>
    );
};
