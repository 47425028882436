import { IJobDetails } from "../../../services/types";
import { useInfiniteQuery, QueryFunctionContext } from "@tanstack/react-query";
import { getJobs } from "../../../services/api";

export const useJobs = (limit: number) => {
    return useInfiniteQuery<IJobDetails[], Error>({
        initialData: undefined,
        initialPageParam: undefined,
        queryKey: ['jobs'],
        queryFn: async ({ pageParam = 0 }: QueryFunctionContext): Promise<IJobDetails[]> => {
            const jobs: IJobDetails[] = await getJobs({
                limit,
                offset: pageParam as number,
            });
            return jobs;
        },
        getNextPageParam: (lastPage, allPages) => {
            const nextOffset = allPages.length * limit;
            const lastPageLength = lastPage.length;
            return lastPageLength === limit ? nextOffset : undefined;
        },
        staleTime: 0,
    });
};
