import {FC, useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Alert, Card, Col, message, Row} from 'antd';
import {useMutation, useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    getAlertConfigs,
    getHomeofficeOptions,
    getSearchById,
    getSearchLocation,
    updateSearchById
} from "../../services/api";
import {PageTitle} from "candidate/src/components/PageTitle.tsx";
import {Loader} from "../../components/Loader.tsx";
import {GeocodedMap} from "../../components/Map";
import {AddressModal} from "../../components/AddressModal.tsx";
import {SelectOptions} from "../../util/utils.ts";
import {IJobDetails, ILocation, JobTypeOption} from "../../services/types.ts";
import {ProfessionModal} from "../../components/ProfessionModal.tsx";
import {DetailItem} from "./components/DetailItem.tsx";
import {ValueType} from "./components/EditModal.tsx";

const jobTypeOptions: SelectOptions = [
    {id: 1, value: "1", label: "Festanstellung"},
    {id: 2, value: "2", label: "Projektbasiert"}
]
const workLoadOptions: SelectOptions = [
    {id: 1, value: "1", label: "Mindestens 5h pro Woche"},
    {id: 2, value: "2", label: "Mindestens 10h pro Woche (empfohlen für höchste Reichweite)"},
    {id: 3, value: "3", label: "Mindestens 20h pro Woche"}]

export const EditSearch: FC = () => {
    const {id = ""} = useParams<{ id: string }>();
    const [isLocationEditorOpen, setIsLocationEditorOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);
    const [isWhatModalOpen, setIsWhatModalOpen] = useState(false);

    const {data, isLoading, refetch, error} = useQuery({
        queryKey: ['search', id],
        queryFn: () => getSearchById(Number(id)),
        enabled: !!id,
    });

    const {data: alertOptions = []} = useQuery({queryKey: ["alertOptions"], queryFn: getAlertConfigs})


    const {data: searchLocation = []} = useQuery({
        queryKey: ["searchLocation"],
        queryFn: () => getSearchLocation(data?.search_location_label || "All"),
        enabled: !!data?.search_location_label
    });

    const {data: homeofficeOptions}: UseQueryResult<JobTypeOption[], Error> = useQuery({
        queryKey: ['homeofficeOptions'],
        queryFn: getHomeofficeOptions,
    });

    const updateMutation = useMutation({
        mutationFn: (updatedData: Partial<IJobDetails>) => updateSearchById(Number(id), updatedData),
        onSuccess: () => {
            message.success('Eingabe erfolgreich gespeichert');
            refetch();
        },
        onError: (error) => {
            message.error(`Die Stellenausschreibung konnte nicht aktualisiert werden: ${error}`);
        },
    });

    const handleSaveLocation = useCallback((location: ILocation | null) => {
        if (location && data) {
            const updatedData: Partial<IJobDetails> = {
                search_location_label: location.label,
                search_location_id: location.id,
                regions_id: location.type === 'region_id' ? Number(location.value) || 0 : 0,
                postal_code: location.type !== 'region_id' ? location.value?.toString() || '' : '',
                radius: location.radius || 0,
            };
            updateMutation.mutate(updatedData);
            setSelectedLocation(location);
        }
        setIsLocationEditorOpen(false);
    }, [data, updateMutation]);

    const handleSaveProfession = (selectedOption: {
        id: number
        label: string
        matching_keywords: string
    } | null) => {

        if (selectedOption && data) {
            const updatedData: Partial<IJobDetails> = {
                search_what_id: selectedOption.id,
                search_what_label: selectedOption.label,
            };
            updateMutation.mutate(updatedData);
        }
        setIsWhatModalOpen(false);
    };

    const handleSaveGeneric = (fields: { [key: string]: unknown }) => {
        if (data) {
            let updatedData: Partial<IJobDetails> = {};
            if ("title" in fields) {
                updatedData.title = (fields.title as { value: string }).value;
            } else {
                updatedData = {...fields} as Partial<IJobDetails>;
            }
            updateMutation.mutate(updatedData);
        }
    };

    const saveHomeoffice = (value: ValueType) => {
        let selectedOption;
        if ("value" in value) {
            selectedOption = homeofficeOptions!.find(option => option.id.toString() === value.value);
        }
        if (selectedOption) {
            handleSaveGeneric({
                homeoffice_label: selectedOption.label,
                homeoffice_id: selectedOption.id
            });
        } else {
            console.warn('No matching homeoffice option found');
        }

    }

    const saveAlertType = (value: { value: string }) => {
        const selectedOption = alertOptions.find(option => option.id.toString() === value.value);

        if (selectedOption) {
            handleSaveGeneric({
                alert_label: selectedOption.config,
                alert_config_id: selectedOption.id
            });
        } else {
            console.warn('No matching alert option found');
        }
    }

    const saveJobTypeAndWorkLoad = ({value: jobTypeId, secondValue: workLoadId}: ValueType) => {
        const selectedJobType = jobTypeOptions?.find(option => option.id.toString() === jobTypeId);
        const selectedWorkLoad = workLoadOptions?.find(option => option.id.toString() === workLoadId);
        handleSaveGeneric({
            job_type_id: selectedJobType?.id,
            job_type_label: selectedJobType?.label,
            work_load_id: selectedWorkLoad?.id,
            work_load_label: selectedWorkLoad?.label
        });
    }

    if (isLoading) {
        return <Loader/>;
    }

    if (error) return <Alert message="Error" description={(error as Error).message} type="error"/>;
    if (!data) return <Alert message="No search data found" type="warning"/>;

    const address = data.search_location_id === 24920 ? "Germany" : data.search_location_label;
    const alertLabel = data.alert_label

    return (
        <div>
            <PageTitle title="Suchbegriff bearbeiten"/>
            <Card style={{marginBottom: '24px'}}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <DetailItem
                            title="Meine Suche"
                            content={data.title}
                            editable
                            inputType="input"
                            onSave={(value) => handleSaveGeneric({title: value})}
                        />
                        <DetailItem
                            title="Gesuchter Beruf oder Tätigkeit"
                            content={`Was?: ${data.search_what_label}`}
                            editable
                            onEdit={() => setIsWhatModalOpen(true)}
                        />
                        <DetailItem
                            title="Homeoffice - Möglichkeiten"
                            content={`Homeoffice: ${data.homeoffice_label}`}
                            editable
                            inputType="select"
                            options={homeofficeOptions?.map((option) => ({
                                value: option.id.toString(),
                                label: option.label,
                            }))
                            }
                            onSave={saveHomeoffice}
                        />
                        <DetailItem
                            title="Stellentyp und -pensum"
                            content={`Arbeitspensum: ${data.work_load_label}  Typ: ${data.job_type_label}`}
                            editable
                            inputType="double-select"
                            options={jobTypeOptions?.map((option) => ({
                                value: option.id.toString(),
                                label: option.label,
                            }))}
                            secondOptions={workLoadOptions?.map((option) => ({
                                value: option.id.toString(),
                                label: option.label,
                            }))}
                            onSave={saveJobTypeAndWorkLoad}
                        />
                        <DetailItem
                            title="Einstellungen"
                            content={`Kandidatenvorschläge: ${alertLabel}`}
                            editable
                            inputType="select"
                            options={alertOptions.map(el => ({id: el.id, value: String(el.id), label: el.config}))}
                            onSave={saveAlertType}
                        />
                    </Col>
                    <Col span={12}>
                        <DetailItem
                            title="Suchort"
                            content={`Wo?: ${selectedLocation?.label || data?.search_location_label}`}
                            editable
                            onEdit={() => setIsLocationEditorOpen(true)}
                        />
                        <GeocodedMap address={address}/>
                    </Col>
                </Row>
            </Card>
            <AddressModal
                open={isLocationEditorOpen}
                onClose={() => setIsLocationEditorOpen(false)}
                onSave={handleSaveLocation}
                initialValue={searchLocation[1]}
            />
            <ProfessionModal
                isModalOpen={isWhatModalOpen}
                handleSaveProf={handleSaveProfession}
                onClose={() => setIsWhatModalOpen(false)}
                initialValue={data.search_what_label}
            />
        </div>
    );
};
