import {Alert, Button, Form, Input, Radio, Typography} from "antd";
import {LockOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";
import logo from "../../assets/logo.png";
import {useLogin} from "./hooks/useLogin.ts";
import styled from "styled-components";
import clientApp from "../../assets/client-app.jpg";
import {Loader} from "../../components/Loader.tsx";

const {Text, Title} = Typography;

export const Login = ({isSignup: initialIsSignup = false}) => {
    const {
        isSignup,
        onSubmit,
        toggleSignup,
        styles,
        hasRedirect,
        error
    } = useLogin(initialIsSignup);

    if (hasRedirect) {
        return <Loader/>
    }
    const options = [
        {label: 'Privatperson', value: true},
        {label: 'Geschäftskunde', value: false},
    ];


    return (
        <Wrapper isSignup={isSignup}>
            <SectionWrapper isSignup={isSignup}>
                <div style={styles.container}>
                    <div style={styles.header}>
                        <img src={logo as string} alt="Silvertalent logo" width={200}/>
                    </div>
                    {error && (
                        <Alert
                            message={error}
                            type="error"
                            showIcon
                            style={{marginBottom: 16}}
                        />
                    )}
                    {isSignup && (
                        <Title style={styles.title}>
                            Probieren Sie Silvertalent jetzt aus und schalten Sie Ihre Stellenanzeige kostenlos!
                        </Title>
                    )}
                    <br/>
                    <Form
                        name="auth_form"
                        onFinish={onSubmit}
                        layout="vertical"
                        requiredMark="optional"
                    >
                        {isSignup && (<Form.Item
                            name="isPrivate"
                            rules={[
                                {required: true, message: "Bitte wählen Sie eine Option"},
                            ]}
                        >

                            <Radio.Group
                                size='large'
                                options={options}
                                buttonStyle="solid"
                            />
                        </Form.Item>)}
                        <Form.Item
                            name="email"
                            rules={[
                                {type: "email", required: true, message: "Bitte geben Sie Ihre E-Mail-Adresse ein"},
                            ]}
                        >
                            <Input prefix={<MailOutlined/>} size="large"
                                   placeholder="Email eingeben..."/>
                        </Form.Item>
                        {isSignup && (
                            <Form.Item
                                name="telephone"
                                rules={[
                                    {required: true, message: "Bitte geben Sie Ihre Telefonnummer ein"},
                                    {
                                        pattern: /^(\+49|0)[1-9]\d{1,14}$/,
                                        message: "Bitte geben Sie eine gültige deutsche Telefonnummer ein",
                                    },
                                ]}
                            >
                                <Input size="large" prefix={<PhoneOutlined/>} placeholder="Telefonnummer"/>
                            </Form.Item>
                        )}
                        <Form.Item
                            name="password"
                            rules={[
                                {required: true, message: "Bitte geben Sie Ihr Passwort ein"},
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined/>}
                                size="large"
                                type="password"
                                placeholder="Passwort eingeben..."
                            />
                        </Form.Item>
                        <Form.Item>
                            <a
                                style={styles.forgotPassword}
                                href="https://www.silvertalent.com/password/password-reset-email"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Password vergessen?
                            </a>
                        </Form.Item>
                        <Form.Item style={{marginBottom: 0}}>
                            <Button block type="primary" htmlType="submit" size="large">
                                {isSignup ? "Registrieren" : "Anmelden"}
                            </Button>
                            <div style={styles.footer}>
                                <div style={styles.footerBtn}>
                                    <Text style={styles.text}>
                                        {isSignup ? "Sie haben schon ein Konto?" : "Sie haben noch kein Kundenkonto?"}
                                    </Text>
                                    <Text style={styles.link} onClick={toggleSignup}>
                                        {isSignup ? "Anmelden" : "Konto anlegen"}
                                    </Text>
                                </div>
                                {isSignup && (
                                    <>
                                        <Text style={styles.termscond}>
                                            Mit der Anmeldung stimme ich den{" "}
                                            <a
                                                target="_blank"
                                                href="https://www.silvertalent.com/legal/datenschutz"
                                                rel="noopener noreferrer"
                                            >
                                                AGB
                                            </a>.
                                        </Text>
                                        <br/>
                                        <Text style={styles.termscond}>
                                            zu und nehme die{" "}
                                            <a
                                                target="_blank"
                                                href="https://www.silvertalent.com/legal/datenschutz"
                                                rel="noopener noreferrer"
                                            >
                                                Datenschutzerklärung
                                            </a>
                                            {" "}zur Kenntnis.
                                        </Text>
                                    </>
                                )}
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </SectionWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ isSignup: boolean }>`
    background: ${props => props.isSignup ? "url(" + clientApp + ")" : "none"};
    background-size: cover;
    @media (max-width: 767px) {
        background: none;
    }
`;
const SectionWrapper = styled.section<{ isSignup: boolean }>`
    align-items: center;
    background-color: ${props => props.isSignup ? "rgba(7, 58, 106, 0.33)" : "rgb(248, 249, 251)"};
    display: flex;
    height: 100vh;
    padding: 0px;
    @media (max-width: 767px) {
        background-color: rgb(248, 249, 251);
    }
`;
