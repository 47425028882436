import { useState, useRef } from 'react';
import { Button, Input, message, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import {PageTitle} from "candidate/src/components/PageTitle.tsx";

const { Paragraph } = Typography;

export const Recommendation = () => {
    const [referralLink] = useState('https://www.silvertalent.de/demo-request?referral=590d0422f2473c6d985e515543e1733679');
    const inputRef = useRef(null);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            message.success('Link wurde in die Zwischenablage kopiert!');
        }, () => {
            message.error('Kopieren fehlgeschlagen. Bitte versuchen Sie es erneut.');
        });
    };

    return (
        <div>
            <PageTitle title="Weiterempfehlungen"/>
            <Paragraph>
                Empfehlen Sie uns an ein anderes Unternehmen über den Link unten weiter! Einfach kopieren und z.B. per
                Email oder Whatsapp versenden.
            </Paragraph>

            <Paragraph>
                Sobald wir mit dem Unternehmen Umsatz machen, bedanken wir uns mit einem 20€ Amazon Gutschein bei Ihnen!
            </Paragraph>

            <Input
                ref={inputRef}
                size="large"
                value={referralLink}
                readOnly
                style={{
                    display: 'inline-block',
                    marginBottom: 8,
                    maxWidth: 500,
                }}
            />
            <br />
            <Button
                type="primary"
                icon={<CopyOutlined/>}
                onClick={copyToClipboard}
            >
                Link kopieren
            </Button>
        </div>
    );
};
