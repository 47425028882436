import { Grid, theme } from "antd";

const { useToken } = theme;

export const useStyles = (screens: ReturnType<typeof Grid.useBreakpoint>): { [key: string]: React.CSSProperties }  => {
    const { token } = useToken();

    return {
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: "430px",
            backgroundColor: "rgb(248 249 251)",
            borderRadius: 10
        },
        footer: {
            marginTop: token.marginLG,
            textAlign: "left",
            width: "100%",
        },
        forgotPassword: {
            float: "left",
        },
        header: {
            marginBottom: token.marginXL,
            display: "flex",
            justifyContent: "center",
        },
        link: {
            cursor: "pointer",
            color: token.colorPrimary,
            fontSize: 14,
        },
        text: {
            color: token.colorTextSecondary,
            fontSize: 14,
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading4 : token.fontSizeHeading5,
            textAlign: "center",
        },
        termscond: {
            fontSize: 12,
            textAlign: "justify",
        },
        footerBtn: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 16,
            gap: 8,
        },
    };
};

