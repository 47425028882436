import {ILocation} from "../services/types.ts";

export const defaultWhereOptions: ILocation[] = [
    {
        id: -1,
        created_at: Date.now(),
        label: "Alle Regionen und Postleitzahlen",
        type: "all",
        value: undefined,
        pattern: "",
        search: "",
        default: "1",
        radius: undefined,
        show: 1,
        label_candidate: "Alle Regionen und Postleitzahlen",
        show_client: 1,
        label_client: "Alle Regionen und Postleitzahlen"
    },
    {
        id: 4,
        created_at: Date.now(),
        label: "Homeoffice",
        type: "region_id",
        value: 2,
        pattern: "",
        search: "",
        default: "0",
        radius: 0,
        show: 1,
        label_candidate: "Homeoffice",
        show_client: 1,
        label_client: "Homeoffice",
    }
];

export type SelectOptions = { id: number, value: string, label: string }[];
export const alertOptions: SelectOptions = [
    {id: 1, value: "1", label: 'Sofortige Benachrichtigung für jeden Match'},
    {id: 2, value: "2", label: 'Benachrichtigungen pro Tag bündeln'},
    {id: 3, value: "3", label: 'Benachrichtigungen pro Woche bündeln'},
    {id: 4, value: "4", label: 'Keine Benachrichtigung'},
]
