import {Button} from "antd";
import {MessageOutlined, UserOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {TruncatedEmployerInfo} from "./TruncatedEmployerInfo.tsx";
import {ICandidate} from "../../../services/types.ts";
import {
    BgWrapper,
    InfoContainer,
    ItemBtns,
    ItemDetails,
    PoolItemMeta,
    RowItemWrapper
} from "candidate/src/pages/jobPool/components/JobPoolItem.tsx";
import {useMediaQuery} from "@uidotdev/usehooks";

export interface ICandidateItem {
    item: ICandidate
}

export const CandidateItem = ({item}: ICandidateItem) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    return (
        <RowItemWrapper onClick={() => isMobile && navigate(`/candidate/${item.key}`)}>
            <BgWrapper>
                <ItemDetails>
                    <InfoContainer>
                        {item.main_job?.job_position &&
                            <h3 style={{width: "70%"}}>{item.main_job.job_position}, {item.main_job.job_experience}</h3>}
                        {item.display?.last_employer?.employer_department && (
                            <PoolItemMeta>
                                <TruncatedEmployerInfo item={item}/>
                            </PoolItemMeta>
                        )}
                        {item.display?.work_experience &&
                            <PoolItemMeta>Weitere Berufserfahrung: {item.display.work_experience}</PoolItemMeta>}
                        {item.education?.degree &&
                            <PoolItemMeta>Berufsausbildung: {item.education.degree}</PoolItemMeta>}
                        {item?.match_label &&
                            <PoolItemMeta><b>Weitere vorstellbare Tätigkeit: {item.match_label}</b></PoolItemMeta>}
                        {item.location?.postal_code &&
                            <PoolItemMeta>{item.location.postal_code} {item.display.city}</PoolItemMeta>}
                    </InfoContainer>
                </ItemDetails>
                <ItemBtns>
                    {!isMobile &&
                        <Button onClick={() => navigate(`/candidate/${item.key}`)} type="primary" shape="round"
                                icon={<UserOutlined/>} style={{width: 200}}>
                            Profil
                        </Button>}
                    {item._outreaches_to_candidate && <CandOutreached>
                        <MessageOutlined/>
                        <p>Kontaktiert</p>
                    </CandOutreached>
                    }
                </ItemBtns>
            </BgWrapper>
        </RowItemWrapper>
    )
}

const CandOutreached = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    p {
        margin: 0;
    }
`
